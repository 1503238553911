import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kt-viewdoc',
  templateUrl: './viewdoc.component.html',
  styleUrls: ['./viewdoc.component.scss']
})
export class ViewdocComponent implements OnInit {

  constructor( public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ViewdocComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
