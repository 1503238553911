import { Injectable } from "@angular/core";
import { Suggestions } from "../../models/suggestions";
import { combineLatest, Observable, Subject } from "rxjs";
import * as firebase from "firebase";
import DataSnapshot = firebase.database.DataSnapshot;
import {
	AngularFirestore,
	AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { first, map, switchMap } from "rxjs/operators";
import {UserDataService} from './user-data.service';
import {User} from '../../models/user';
import {NotificationService} from './notification.service';

@Injectable({
	providedIn: "root",
})
export class SuggestionService {
	suggestion: Suggestions[] = [];
	id: string = "";
	suggestionSubject = new Subject<Suggestions[]>();
	constructor(private firestore: AngularFirestore,
				private userService: UserDataService,
				private notificationService: NotificationService) {}
	filtredSug = [];
	emitAnnonce() {
		this.suggestionSubject.next(this.suggestion);
	}
	getlist() {
		const item: AngularFirestoreCollection = this.firestore.collection(
			`Suggestion`
		);
		return item.valueChanges().pipe(
			switchMap((arr) => {
				const suggestionObservable = arr.map((suggestion) =>
					this.firestore
						.collection("users")
						.doc(`/${suggestion.user_uid}`)
						.valueChanges()
						.pipe(first())
				);
				return combineLatest(...suggestionObservable).pipe(
					map((...user) => {
						arr.forEach((uid, index) => {
							if (
								user[0][index]["city"] ==
								localStorage.getItem("city")
							) {
								uid["city"] = user[0][index]["city"];
							}
						});
						arr.map((a) => {
							if (a.city != null) {
								this.filtredSug.push(a);
							}
						});
						return this.filtredSug;
					})
				);
			})
		);
	}
	getListSuggestions() {
		return this.firestore
			.collection("Suggestion", (ref) =>
			ref.where("city", "==", localStorage.getItem("city"))
			) //
			.snapshotChanges();
	}
	updateSuggestion(data) {
		this.id = data.id;

		if (data.status == 2 && data.user_uid != null){
			this.userService.getuserById(data.user_uid).subscribe(
				(data: User) => {
					let notificationCor = {
						"notification" : {
							"title": "Retour De Votre Correspondence",
							"body": "Votre demande a été Traité"
						},
						"to" :  data.userToken
					}
                this.notificationService.pushNotification(notificationCor).subscribe(
					(data) =>  console.log ('Notification Send succus'),
								(error) => console.log(error)
				);
		})};
		return this.firestore.collection("Suggestion").doc(this.id).set({
			datemodif: data.datemodif,
			status: data.status,
			category: data.category,
			contenu: data.contenu,
			nature: data.nature,
			city: localStorage.getItem('city'),
			user_uid: data.user_uid,
		});
	}
	saveSuggestions(suggestion: Suggestions): Promise<Suggestions> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Suggestion/")
				.add({
					category: suggestion.category,
					contenu: suggestion.contenu,
					nature: suggestion.nature,
					user_uid: suggestion.user.uid,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
}
