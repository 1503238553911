export class Annonces {
	id: string
	city: string;
	date: string;
	description: string;
	image_url:string;
	title: string;

	constructor(city: string, date: string, description: string, image_url: string, title: string) {
		this.city = city;
		this.date = date;
		this.description = description;
		this.image_url = image_url;
		this.title = title;
	}
}
