import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-dureetraitement',
  templateUrl: './dureetraitement.component.html',
  styleUrls: ['./dureetraitement.component.scss']
})
export class DureetraitementComponent implements OnInit {

  
  dureetraitements= environment.dureetraitements;
  constructor() { }

  ngOnInit() {
  }

}
