import { Examen } from "./../../../../../models/examen";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { DocsService } from "../../../../../core/apps/docs.service";
import { ImageService } from "../../../../../core/apps/image.service";
import firebase from "firebase";

class ImageSnippet {
	pending: boolean = false;
	status: string = "init";
	constructor(public src: string, public file: File) {}
}
@Component({
	selector: "kt-adddoc",
	templateUrl: "./adddoc.component.html",
	styleUrls: ["./adddoc.component.scss"],
})
export class AdddocComponent implements OnInit {

	progress: number;
	docForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;

	constructor(
		public dialogRef: MatDialogRef<AdddocComponent>,
		private formBuilder: FormBuilder,
		private imageService: ImageService,
		private docService: DocsService
	) {}

	ngOnInit() {
		this.initForm();
	}
	onNoClick() {
		if (this.fileUrl && this.fileUrl !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl);
			storageRef.delete().then(() => {
				console.log("pas insertion docuement , docuement suprrimé");
			});
		}
		this.dialogRef.close();
	}

	upload(file) {}
	selectedFile: ImageSnippet;

	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "ok";
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "fail";
		this.selectedFile.src = "";
	}

	initForm() {
		this.docForm = this.formBuilder.group({
			entreprise: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const entreprise = this.docForm.get("entreprise").value;
		//const doc_type = this.docForm.get("doc_type").value;
		//const url_file = this.fileUrl && this.fileUrl !== "" ? this.fileUrl : "";
		const newDoc = new Examen();
		newDoc.entreprise = entreprise;
		this.docService.saveDocs(newDoc).then((res) => {});
	}

	processFile(imageInput: any) {
		this.fileisUploading = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();

		reader.addEventListener("load", (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);

			this.selectedFile.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadDoc(this.selectedFile.file).then(
				(url: string) => {
					this.fileUrl = url;
					this.fileisUploading = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
}
