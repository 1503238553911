import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-branchie',
  templateUrl: './branchie.component.html',
  styleUrls: ['./branchie.component.scss']
})
export class BranchieComponent implements OnInit {

  
  branchies= environment.branchies;
  constructor() { }

  ngOnInit() {
  }

}
