import { Examen } from "./../../../../models/examen";
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { DocsService } from '../../../../core/apps/docs.service';
import { Subject } from 'rxjs';
import { ModifierAnnonceComponent } from '../annonces/modifier-annonce/modifier-annonce.component';
import { AdddocComponent } from './adddoc/adddoc.component';
import { UpdatedocComponent } from './updatedoc/updatedoc.component';
import { ViewdocComponent } from './viewdoc/viewdoc.component';

@Component({
  selector: 'kt-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

	displayedColumns = ["select", "Titre", "Type", "actions"];
	selection = new SelectionModel<Examen>(false, []);
	docs: Examen;
	docsTab  = [];
  lastTap= [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private docService: DocsService,
		private layoutUtilsService: LayoutUtilsService,
		public dialog: MatDialog,
  ) { }
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<any>;
  ngOnInit() {
    console.log(Date.now().toString())
		this.dataSource = new MatTableDataSource([]);
		let sub=this.docService.getListDocs()
			.subscribe((actions) => {
				this.docsTab=[];
				return actions.map((a) => {
					let data = a.payload.doc.data() as Examen;
					data.id = a.payload.doc.id;
					//let annonceToString = null;
					let annonceToString = JSON.stringify(
						Object.assign({}, data)
					);
					let stringToJsonObject = JSON.parse(annonceToString);
					this.docs = stringToJsonObject;
					this.docsTab.push(this.docs);
					this.dataSource.data = this.docsTab;
				/*	this.annonces =null
					this.lastTap=null
					sub.unsubscribe();  */
				});
			});
  }
  applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
  }
  	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  openDialog(annocne: any): void {	  
	let dialogRef = this.dialog.open(ViewdocComponent, {
		width: "650px",
		data: {
			doc_url: annocne[0].doc_url,
		},
	});
	dialogRef.afterClosed().subscribe((result) => {
		/*(val) => console.log(val)*/
		console.log("The dialog was closed");
	});
}
  modifierDialog(annocne: any): void {
		let dialogRef = this.dialog.open(UpdatedocComponent, {
			width: "650px",
			data: {
				id: annocne.id,
				doc_name: annocne.doc_name,
				doc_type: annocne.doc_type,
				city: annocne.city,
				doc_url: annocne.doc_url,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			/!*(val) => console.log(val)*!/
			console.log("The dialog was closed");
		});
	}
	openDialogAddAnnonce() {
		let dialogRef = this.dialog.open(AdddocComponent, {
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			/*(val) => console.log(val)*/
			console.log("The dialog was closed");
		});
	}

}
