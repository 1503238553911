import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-cerveau',
  templateUrl: './cerveau.component.html',
  styleUrls: ['./cerveau.component.scss']
})
export class CerveauComponent implements OnInit {
  
  cerveaux= environment.cerveaux;
  constructor() { }

  ngOnInit() {
  }

}
