import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-especes',
  templateUrl: './especes.component.html',
  styleUrls: ['./especes.component.scss']
})
export class EspecesComponent implements OnInit {
  especes= environment.especes;
  constructor() { }

  ngOnInit() {
  }

}
