import { User } from "./user";
export class Suggestions {
	id: string;
	category: string;
	contenu: string;
	nature: string;
	date: string;
	datemodif: Date;
	status: string;
	user: User;

	constructor(
		category: string,
		contenu: string,
		nature: string,
		user: User,
		date: string,
		datemodif: Date,
		status: string
	) {
		this.category = category;
		this.contenu = contenu;
		this.nature = nature;
		this.datemodif = datemodif;
		this.user = user;
		this.date = date;
		this.status = status;
	}
}
