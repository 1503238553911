import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-showimages',
  templateUrl: './showimages.component.html',
  styleUrls: ['./showimages.component.scss']
})
export class ShowimagesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShowimagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit() {
  }

}
