import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";
@Component({
  selector: 'kt-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.scss']
})
export class EntrepriseComponent implements OnInit {

  constructor() { }
  entreprises= environment.entreprise;
  ngOnInit() {
  }

}
