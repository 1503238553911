import { Docs } from "./../../models/docs";
import { Examen } from "./../../models/examen";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class DocsService {
	docs: Docs[] = [];
	DocsSubject = new Subject<Docs[]>();
	id: string = "";
	constructor(private firestore: AngularFirestore) {}
	emitAnnonce() {
		this.DocsSubject.next(this.docs);
	}
	getListDocs() {
		return this.firestore
			.collection("docs", (ref) =>
				ref.where("city", "==", localStorage.getItem("city"))
			)
			.snapshotChanges();
	}
	updatedoc(data) {
		this.id = data.id;
		return this.firestore.collection("docs").doc(this.id).set({
			city: data.city,
			doc_name: data.doc_name,
			doc_type: data.doc_type,
			doc_url: data.doc_url,
		});
	}
	saveDocs(doc: Examen): Promise<Docs> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("examens/")
				.add({
					entreprise: doc.entreprise,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
}
