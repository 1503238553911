import { map, takeUntil } from "rxjs/operators";

import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { AnnoncesService } from "../../../../core/apps/annonces.service";
import { Annonces } from "../../../../models/annonces";
import { async, Observable, Subject } from "rxjs";
import { LayoutUtilsService } from "../../../../core/_base/crud";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AddAnnonceComponent } from "./add-annonce/add-annonce.component";
import { DialogComponent } from "../dialog/dialog.component";
import { AngularFirestore } from "@angular/fire/firestore";
import {ModifierAnnonceComponent} from './modifier-annonce/modifier-annonce.component';
import {ImageService} from '../../../../core/apps/image.service';
import {Usertoken} from '../../../../models/usertoken';
import {UserDataService} from '../../../../core/apps/user-data.service';
import {User} from '../../../../models/user';
//import {JsonFormatter} from 'tslint/lib/formatters';

/**
 * @title Table with selection
 */
@Component({
	selector: "kt-annonces",
	templateUrl: "./annonces.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnoncesComponent implements OnInit, OnDestroy {
	displayedColumns = ["select", "Titre", "Date", "actions"];
	selection = new SelectionModel<Annonces>(false, []);
	annonces: Annonces;
	anonceTab  = [];
	lastTap= [];
	userTock =[]
	tab : Usertoken

	/**
	 *
	 * @param router: Router
	 * @param layoutUtilsService: LayoutUtilsService
	 */
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		private annocesService: AnnoncesService,
		private layoutUtilsService: LayoutUtilsService,
		public dialog: MatDialog,
		private userService: UserDataService,
		private firestore: AngularFirestore
	) {}
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<any>;
	ngOnInit() {
		this.dataSource = new MatTableDataSource([]);
		let sub=this.annocesService.getListAnnonces()
			.subscribe((actions) => {
				this.anonceTab=[];
				return actions.map((a) => {
					let data = a.payload.doc.data() as Annonces;
					data.id = a.payload.doc.id;
					//let annonceToString = null;
					let annonceToString = JSON.stringify(
						Object.assign({}, data)
					);
					let stringToJsonObject = JSON.parse(annonceToString);
					this.annonces = stringToJsonObject;
					this.anonceTab.push(this.annonces);
					this.dataSource.data = this.anonceTab;
				/*	this.annonces =null
					this.lastTap=null
					sub.unsubscribe();  */
				});
			});
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
	}

	/**
	 * Fetch selected rows
	 */
	fetchAnnonce(annoce: any) {
		const messages = [];
		messages.push({
			title: annoce[0].title,
			description: annoce[0].description,
			date: annoce[0].date,
		});
		this.layoutUtilsService.fetchElements(messages);
	}

	editUser(id) {
		/*this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });*/
	}

	openDialog(annocne: any): void {
		let dialogRef = this.dialog.open(DialogComponent, {
			width: "650px",
			data: {
				title: annocne[0].title,
				description: annocne[0].description,
				date: annocne[0].date,
				city: annocne[0].city,
				link: annocne[0].image_url,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	ngOnDestroy() {
		if (this.annocesService) {
			this.annocesService.getListAnnonces().pipe(takeUntil(this.destroy$));
		}
	}
	modifierDialog(annocne: any): void {
		let dialogRef = this.dialog.open(ModifierAnnonceComponent, {
			width: "650px",
			data: {
				id: annocne.id,
				title: annocne.title,
				description: annocne.description,
				date: annocne.date,
				city: annocne.city,
				link: annocne.image_url,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			/!*(val) => console.log(val)*!/
			console.log("The dialog was closed");
		});
	}

	openDialogAddAnnonce() {
		let dialogRef = this.dialog.open(AddAnnonceComponent, {
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			/*(val) => console.log(val)*/
			console.log("The dialog was closed");
		});
	}
}

export interface Element {
	Titre: string;
	Date: number;
}
