import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SuggestionService } from "../../../../../core/apps/suggestion.service";
import { UserDataService } from "../../../../../core/apps/user-data.service";
import {User} from '../../../../../models/user';
import {Console} from 'inspector';

@Component({
	selector: "kt-modifsuggestion",
	templateUrl: "./modifsuggestion.component.html",
	styleUrls: ["./modifsuggestion.component.scss"],
})
export class ModifsuggestionComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<ModifsuggestionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private suggestionService: SuggestionService,
		private userService: UserDataService
	) {}
	selectedValue: string;
	list = [
		{ value: 0, viewValue: "Délivré" },
		{ value: 1, viewValue: "En cours de traitement" },
		{ value: 2, viewValue: "Traité et résolu" },
		{ value: 3, viewValue: "Traité et non résolu" },
	];
	ngOnInit(){
	}

	update(data, statut) {
		data.status = statut;
		data.datemodif = Date.now();
		if (statut == 0) {
			alert("Il ne faut pas changer le statut en Délivré!");
		}
		else {
			this.suggestionService.updateSuggestion(data);
			this.dialogRef.close();
		}
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
}
