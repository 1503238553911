import { Injectable } from "@angular/core";
import {
	AngularFirestore,
} from "@angular/fire/firestore";
import {  Subject } from "rxjs";
import { Alert } from "../../models/alert";
import {User} from '../../models/user';
import {UserDataService} from './user-data.service';
import {NotificationService} from './notification.service';
@Injectable({
	providedIn: "root",
})
export class AlertService {
	alert: Alert[] = [];
	id: string = "";
	suggestionSubject = new Subject<Alert[]>();
	constructor(private firestore: AngularFirestore,
				private userService: UserDataService,
				private notificationService: NotificationService
	) {}

	emitAnnonce() {
		this.suggestionSubject.next(this.alert);
	}
	getListAlert() {
		return this.firestore
			.collection("alert", (ref) =>
				ref.where("city", "==", localStorage.getItem("city"))
			) //
			.snapshotChanges();
	}
	updateAlert(data) {
		this.id = data.id;
		console.log(data.statutAlert)
		if(data.statutAlert == 3 && data.user_uid != null){
			this.userService.getuserById(data.user_uid).subscribe(
				(data: User) => {
					let notificationCor = {
						"notification" : {
							"title": "Retour De Votre Signalment",
							"body": "Votre alert a été Traité"
						},
						"to" :  data.userToken
					}
					this.notificationService.pushNotification(notificationCor).subscribe(
						(data) =>  {console.log ('Notification Send succus')},
						(error) => console.log(error)
					)
				});
		}
		return this.firestore.collection("alert").doc(this.id).set({
			category: data.category,
			city: data.city,
			discription: data.discription,
			image_url: data.image_url,
			latitude: data.latitude,
			longitude: data.longitude,
			statutAlert: data.statutAlert,
			user_uid: data.user_uid,
		});
	}
}
