import { Examen } from "./../../models/examen";
import { Injectable } from "@angular/core";
import {
	AngularFirestore,
} from "@angular/fire/firestore";
import {  Observable, Subject } from "rxjs";
import { Conduite } from "../../models/conduite";
import { Diagnostic } from "../../models/diagnostic";
import { Autres } from "../../models/autres";
import { Parasitaire } from "../../models/parasitaire";
import { Bacteriologique } from "../../models/bacteriologique";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ConduiteService {

  conduite: Conduite[] = [];
  parasitaire: Parasitaire[] = [];
  bacteriologique: Bacteriologique[] = [];
  diagnostic: Diagnostic[] = [];
  examen: Examen[] = [];
  autres: Autres[] = [];
  id: string = "";
  myDate = new Date();
	suggestionSubject = new Subject<Conduite[]>();
	parasitaireSubject = new Subject<Parasitaire[]>();
	bacteriologiqueSubject = new Subject<Bacteriologique[]>();
	suggestionSubjectdiagnostic = new Subject<Diagnostic[]>();
	suggestionSubjectExamen = new Subject<Examen[]>();
	suggestionSubjectAutres = new Subject<Autres[]>();
	constructor(private firestore: AngularFirestore,
		private datePipe: DatePipe
	) {}

  emitConduite() {
		this.suggestionSubject.next(this.conduite);
	}
  emitparasitaire() {
		this.parasitaireSubject.next(this.parasitaire);
	}
  emitbacteriologique() {
		this.bacteriologiqueSubject.next(this.bacteriologique);
	}
  emitDiagnostic() {
		this.suggestionSubjectdiagnostic.next(this.diagnostic);
	}
  emitexamen() {
		this.suggestionSubjectExamen.next(this.examen);
	}
  emitautres() {
		this.suggestionSubjectAutres.next(this.autres);
	}

	getListparasitaire() {
		return this.firestore
			.collection("Parasitaire")
			.snapshotChanges();
	}
	getListbacteriologique() {
		return this.firestore
			.collection("Bacteriologique")
			.snapshotChanges();
	}
	getListConduite() {
		return this.firestore
			.collection("Conduites")
			.snapshotChanges();
	}
	getListdiagnostic() {
		return this.firestore
			.collection("diagnostics")
			.snapshotChanges();
	}
	getListexamen() {
		return this.firestore
			.collection("examens", ref => 
			ref.orderBy('dateExamen','desc'))
			.snapshotChanges();
	}
	getListautres() {
		return this.firestore
			.collection("autres")
			.snapshotChanges();
	}
	updateBacteriologique(data) {
		this.id = data.id;
		return this.firestore.collection("Bacteriologique").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateParasitaire(data) {
		this.id = data.id;
		return this.firestore.collection("Parasitaire").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateConduite(data) {
		this.id = data.id;
		return this.firestore.collection("Conduites").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updatediagnostic(data) {
		this.id = data.id;
		return this.firestore.collection("diagnostics").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	updateExamen(data) {
		console.log(data.id);
		
		this.id = data.id;
		return this.firestore.collection("examens").doc(this.id).set({
					dateModification: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),
					//image: data.image,
					bacteriologique: data.bacteriologique,
					parasitaire: data.parasitaire,
					dateExamen: data.dateExamen,
					entreprise: data.entreprise,
					numCage: data.numCage,
					espece: data.espece,
					ecloserie: data.ecloserie,
					tempEau: data.tempEau,
					dateRecepetion: data.dateRecepetion,
					pm: data.pm,
					mortaliteparjours: data.mortaliteparjours,
					nbrJoursMortalite: data.nbrJoursMortalite,
					totalMortalite: data.totalMortalite,
					incident: data.incident,


					embonpointobservation : data.embonpointobservation,
   					embonpointfrequence :data. embonpointfrequence,
    				embonpointseverite : data.embonpointseverite,

					//revetement: revetement,
					revetementobservation : data.revetementobservation,
					revetementfrequence : data.revetementfrequence,
					revetementseverite : data.revetementseverite,

					//nageoires: data.nageoires,
					nageoiresobservation :data. nageoiresobservation,
					nageoiresfrequence : data.nageoiresfrequence,
					nageoiresseverite : data.nageoiresseverite,

					//opercule: data.opercule,
					operculeobservation : data.operculeobservation,
					operculefrequence : data.operculefrequence,
					operculeseverite : data.operculeseverite,

					//bouche: data.bouche,
					boucheobservation : data.boucheobservation,
					bouchefrequence : data.bouchefrequence,
					boucheseverite : data.boucheseverite,

					//estomac: data.estomac,
					estomacobservation : data.estomacobservation,
					estomacfrequence : data.estomacfrequence,
					estomacseverite : data.estomacseverite,

					//intestin: data.intestin,
					intestinobservation :data.intestinobservation,
					intestinfrequence : data.intestinfrequence,
					intestinseverite : data.intestinseverite,

					//branchie: data.branchie,
					branchieobservation : data.branchieobservation,
					branchiefrequence : data.branchiefrequence,
					branchieseverite : data.branchieseverite,

					//coeur: data.coeur,
					coeurobservation : data.coeurobservation,
					coeurfrequence : data.coeurobservation,
					coeurseverite : data.coeurseverite,

					//tete: data.tete,
					teteobservation : data.teteobservation,
					tetefrequence : data.tetefrequence,
					teteseverite : data.teteseverite,

					//yeux: data.yeux,
					yeuxobservation : data.yeuxobservation,
					yeuxfrequence : data.yeuxfrequence,
					yeuxseverite : data.yeuxseverite,

					//cerveau: data.cerveau,
					cerveauobservation : data.cerveauobservation,
					cerveaufrequence : data.cerveaufrequence,
					cerveauseverite : data.cerveauseverite,

					//rate: data.rate,
					rateobservation : data.rateobservation,
					ratefrequence : data.ratefrequence,
					rateseverite : data.rateseverite,

					//foie: data.foie,
					foieobservation : data.foieobservation,
					foiefrequence : data.foiefrequence,
					foieseverite : data.foieseverite,

					//vesiculeBiliaire: data.vesiculeBiliaire,
					vesiculeBiliaireobservation : data.vesiculeBiliaireobservation,
					vesiculeBiliairefrequence : data.vesiculeBiliairefrequence,
					vesiculeBiliaireseverite : data.vesiculeBiliaireseverite,

					//rein: data.rein,
					reinobservation :data.reinobservation,
					reinfrequence : data.reinfrequence,
					reinseverite : data.reinseverite,

					//vessienatatoire: data.vessienatatoire,
					rechercheparasitaireobservation : data.rechercheparasitaireobservation,
					rechercheparasitaireforme : data.rechercheparasitaireforme,

					recherchebacteriologiqueobservation : data.recherchebacteriologiqueobservation,
					recherchebacteriologiqueforme : data.recherchebacteriologiqueforme,

					vessienatatoireobservation : data.vessienatatoireobservation,
					vessienatatoirefrequence : data.vessienatatoirefrequence,
					vessienatatoireseverite : data.vessienatatoireseverite,

					//autres: data.autres,
					autresobservation : data.autresobservation,
					autresfrequence : data.autresfrequence,
					autresseverite : data.autresseverite,

					//diagnostic: data.diagnostic,
					diagnosticdiagnostictexte : data.diagnosticdiagnostictexte,
					diagnosticobservation : data.diagnosticobservation,
					diagnosticforme : data.diagnosticforme,

					//conduite: data.conduite,
					conduiteconduitetexte : data.conduiteconduitetexte,
					conduiteobservation : data.conduiteobservation ,
					conduitemolecule : data.conduitemolecule ,
					conduitedurretraitement : data.conduitedurretraitement,
					imageurl : data.imageurl===""?"":data.imageurl,
					imageurlconvert : data.imageurlconvert===""?"":data.imageurlconvert,
					imageurl1 : data.imageurl1===""?"":data.imageurl1,
					imageurlconvert1 : data.imageurlconvert1===""?"":data.imageurlconvert1,
					imageurl2 : data.imageurl2===""?"":data.imageurl2,
					imageurlconvert2 : data.imageurlconvert2===""?"":data.imageurlconvert2,
					imageurl3 : data.imageurl3===""?"":data.imageurl3,
					imageurlconvert3 : data.imageurlconvert3===""?"":data.imageurlconvert3,
					imageurl4 : data.imageurl4===""?"":data.imageurl4,
					imageurlconvert4 : data.imageurlconvert4===""?"":data.imageurlconvert4,
	
		});
	}
	getexamensbyid(id: string): Observable<any> {
		//this.id = id;
		return this.firestore
			.collection("examens")
      .doc(id)
      .valueChanges();
	}
	updateAutres(data) {
		this.id = data.id;
		return this.firestore.collection("autres").doc(this.id).set({
			libelle: data.libelle,
		});
	}
	saveparasitaire(conduite: Parasitaire): Promise<Parasitaire> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Parasitaire/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	savebacteriologique(conduite: Bacteriologique): Promise<Bacteriologique> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Bacteriologique/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveConduite(conduite: Conduite): Promise<Conduite> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Conduites/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	savediagnostic(conduite: Diagnostic): Promise<Diagnostic> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("diagnostics/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	saveAutres(conduite: Autres): Promise<Autres> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("autres/")
				.add({
					libelle: conduite.libelle,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	getBase64ImageFromURL(url) {
		return new Promise((resolve, reject) => {
		  var img = new Image();
		  img.setAttribute("crossOrigin", "anonymous");
	  
		  img.onload = () => {
			var canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
	  
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
	  
			var dataURL = canvas.toDataURL("image/png");
	  
			resolve(dataURL);
		  };
	  
		  img.onerror = error => {
			reject(error);
		  };
	  
		  img.src = url;
		});
	  }
	  url2;
	   //method to retrieve download url
	   public async getUrl(snap: firebase.storage.UploadTaskSnapshot) {
	//let url;
   let  url = await snap.ref.getDownloadURL();
   this.url2 = url;
   console.log(url);
   console.log(this.url2);
   
   return this.url2;
  }
	saveExamen(exam: Examen): Promise<Examen> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("examens/")
				.add({
					//image: exam.image,
					//imageurl: exam.imageurl,
					bacteriologique: exam.bacteriologique,
					parasitaire: exam.parasitaire,
					dateExamen: exam.dateExamen,
					entreprise: exam.entreprise,
					numCage: exam.numCage,
					espece: exam.espece,
					ecloserie: exam.ecloserie,
					tempEau: exam.tempEau,
					dateRecepetion: exam.dateRecepetion,
					pm: exam.pm,
					mortaliteparjours: exam.mortaliteparjours,
					nbrJoursMortalite: exam.nbrJoursMortalite,
					totalMortalite: exam.totalMortalite,
					incident: exam.incident,
					embonpointobservation : exam.embonpointobservation,
   					embonpointfrequence :exam. embonpointfrequence,
    				embonpointseverite : exam.embonpointseverite,

					//revetement: revetement,
					revetementobservation : exam.revetementobservation,
					revetementfrequence : exam.revetementfrequence,
					revetementseverite : exam.revetementseverite,

					//nageoires: exam.nageoires,
					nageoiresobservation :exam. nageoiresobservation,
					nageoiresfrequence : exam.nageoiresfrequence,
					nageoiresseverite : exam.nageoiresseverite,

					//opercule: exam.opercule,
					operculeobservation : exam.operculeobservation,
					operculefrequence : exam.operculefrequence,
					operculeseverite : exam.operculeseverite,

					//bouche: exam.boucheobservation,
					boucheobservation : exam.boucheobservation,
					bouchefrequence : exam.bouchefrequence,
					boucheseverite : exam.boucheseverite,

					//estomac: exam.estomac,
					estomacobservation : exam.estomacobservation,
					estomacfrequence : exam.estomacfrequence,
					estomacseverite : exam.estomacseverite,

					//intestin: exam.intestin,
					intestinobservation :exam.intestinobservation,
					intestinfrequence : exam.intestinfrequence,
					intestinseverite : exam.intestinseverite,

					//branchie: exam.branchie,
					branchieobservation : exam.branchieobservation,
					branchiefrequence : exam.branchiefrequence,
					branchieseverite : exam.branchieseverite,

					//coeur: exam.coeur,
					coeurobservation : exam.coeurobservation,
					coeurfrequence : exam.coeurfrequence,
					coeurseverite : exam.coeurseverite,

					//tete: exam.tete,
					teteobservation : exam.teteobservation,
					tetefrequence : exam.tetefrequence,
					teteseverite : exam.teteseverite,

					//yeux: exam.yeux,
					yeuxobservation : exam.yeuxobservation,
					yeuxfrequence : exam.yeuxfrequence,
					yeuxseverite : exam.yeuxseverite,

					//cerveau: exam.cerveau,
					cerveauobservation : exam.cerveauobservation,
					cerveaufrequence : exam.cerveaufrequence,
					cerveauseverite : exam.cerveauseverite,

					//rate: exam.rate,
					rateobservation : exam.rateobservation,
					ratefrequence : exam.ratefrequence,
					rateseverite : exam.rateseverite,

					//foie: exam.foie,
					foieobservation : exam.foieobservation,
					foiefrequence : exam.foiefrequence,
					foieseverite : exam.foieseverite,

					//vesiculeBiliaire: exam.vesiculeBiliaire,
					vesiculeBiliaireobservation : exam.vesiculeBiliaireobservation,
					vesiculeBiliairefrequence : exam.vesiculeBiliairefrequence,
					vesiculeBiliaireseverite : exam.vesiculeBiliaireseverite,

					//rein: exam.rein,
					reinobservation :exam.reinobservation,
					reinfrequence : exam.reinfrequence,
					reinseverite : exam.reinseverite,

					//vessienatatoire: exam.vessienatatoire,
					rechercheparasitaireobservation : exam.rechercheparasitaireobservation,
					rechercheparasitaireforme : exam.rechercheparasitaireforme,

					recherchebacteriologiqueobservation : exam.recherchebacteriologiqueobservation,
					recherchebacteriologiqueforme : exam.recherchebacteriologiqueforme,

					vessienatatoireobservation : exam.vessienatatoireobservation,
					vessienatatoirefrequence : exam.vessienatatoirefrequence,
					vessienatatoireseverite : exam.vessienatatoireseverite,

					//autres: exam.autres,
					autresobservation : exam.autresobservation,
					autresfrequence : exam.autresfrequence,
					autresseverite : exam.autresseverite,

					//diagnostic: exam.diagnostic,
					diagnosticdiagnostictexte : exam.diagnosticdiagnostictexte,
					diagnosticobservation : exam.diagnosticobservation,
					diagnosticforme : exam.diagnosticforme,

					//conduite: exam.conduite,
					conduiteconduitetexte : exam.conduiteconduitetexte,
					conduiteobservation : exam.conduiteobservation,
					conduitemolecule : exam.conduitemolecule,
					conduitedurretraitement : exam.conduitedurretraitement,
					imageurl : exam.imageurl===""?"":exam.imageurl,
					imageurlconvert : exam.imageurlconvert===""?"":exam.imageurlconvert,
					imageurl1 : exam.imageurl1===""?"":exam.imageurl1,
					imageurlconvert1 : exam.imageurlconvert1===""?"":exam.imageurlconvert1,
					imageurl2 : exam.imageurl2===""?"":exam.imageurl2,
					imageurlconvert2 : exam.imageurlconvert2===""?"":exam.imageurlconvert2,
					imageurl3 : exam.imageurl3===""?"":exam.imageurl3,
					imageurlconvert3 : exam.imageurlconvert3===""?"":exam.imageurlconvert3,
					imageurl4 : exam.imageurl4===""?"":exam.imageurl4,
					imageurlconvert4 : exam.imageurlconvert4===""?"":exam.imageurlconvert4,
					//imageurl: exam.imageurl,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
  deleteconduite(id: string) {
		this.firestore.doc("Conduites/" + id).delete();
	}
  deleteDiagnostic(id: string) {
		this.firestore.doc("diagnostics/" + id).delete();
	}
  deleteExamen(id: string) {
		this.firestore.doc("examens/" + id).delete();
	}
  deleteautres(id: string) {
		this.firestore.doc("autres/" + id).delete();
	}
  deleteBacteriologique(id: string) {
		this.firestore.doc("Bacteriologique/" + id).delete();
	}
  deleteParasitaire(id: string) {
		this.firestore.doc("Parasitaire/" + id).delete();
	}
}
