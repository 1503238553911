import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-vesiculebiliaire',
  templateUrl: './vesiculebiliaire.component.html',
  styleUrls: ['./vesiculebiliaire.component.scss']
})
export class VesiculebiliaireComponent implements OnInit {

  
  vesiculebiliaires= environment.vesiculebiliaires;
  constructor() { }

  ngOnInit() {
  }

}
