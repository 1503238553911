import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-forme',
  templateUrl: './forme.component.html',
  styleUrls: ['./forme.component.scss']
})
export class FormeComponent implements OnInit {

  
  formes= environment.formes;
  constructor() { }

  ngOnInit() {
  }

}
