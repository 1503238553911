import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-tete',
  templateUrl: './tete.component.html',
  styleUrls: ['./tete.component.scss']
})
export class TeteComponent implements OnInit {
  
  tetes= environment.tetes;
  constructor() { }

  ngOnInit() {
  }

}
