import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-coeur',
  templateUrl: './coeur.component.html',
  styleUrls: ['./coeur.component.scss']
})
export class CoeurComponent implements OnInit {

  
  coeurs= environment.coeurs;
  constructor() { }

  ngOnInit() {
  }

}
