export class UsersTable {
	public static users: any = [
		{
			id: 1,
			username: 'admin1',
			password: 'testdemo',
			email: 'demo1.cabinet@gmail.com',
			accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
			refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
			roles: [1], // Administrator
			pic: './assets/media/users/default.jpg',
			fullname: 'Dr Majdi Gall',
			occupation: 'Admin',
			companyName: 'Cabinet',
			phone: '456669067890',
			address: {
				addressLine: 'L-12-20 Vertex, Cybersquare',
				city: 'San Francisco',
				state: 'California',
				postCode: '45000'
			},
			socialNetworks: {
				linkedIn: 'https://linkedin.com/admin',
				facebook: 'https://facebook.com/admin',
				twitter: 'https://twitter.com/admin',
				instagram: 'https://instagram.com/admin'
			}
		},
	];

	public static tokens: any = [
		{
			id: 1,
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random()
		}
	];
}
