import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-yeux',
  templateUrl: './yeux.component.html',
  styleUrls: ['./yeux.component.scss']
})
export class YeuxComponent implements OnInit {

  
  yeux= environment.yeux;
  constructor() { }

  ngOnInit() {
  }

}
