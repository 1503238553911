import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-vessienatatoire',
  templateUrl: './vessienatatoire.component.html',
  styleUrls: ['./vessienatatoire.component.scss']
})
export class VessienatatoireComponent implements OnInit {

  
  vessienatatoires= environment.vessienatatoires;
  constructor() { }

  ngOnInit() {
  }

}
