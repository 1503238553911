import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";
@Component({
  selector: 'kt-ecloseries',
  templateUrl: './ecloseries.component.html',
  styleUrls: ['./ecloseries.component.scss']
})
export class EcloseriesComponent implements OnInit {
  ecloseries= environment.ecloseries;
  constructor() { }

  ngOnInit() {
  }

}
