import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-intestin',
  templateUrl: './intestin.component.html',
  styleUrls: ['./intestin.component.scss']
})
export class IntestinComponent implements OnInit {

  intestins= environment.intestins;
  constructor() { }

  ngOnInit() {
  }

}
