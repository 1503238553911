import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-rein',
  templateUrl: './rein.component.html',
  styleUrls: ['./rein.component.scss']
})
export class ReinComponent implements OnInit {

  
  reins= environment.reins;
  constructor() { }

  ngOnInit() {
  }

}
