import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserDataService } from "../../../../../core/apps/user-data.service";
import { AlertService } from "../../../../../core/apps/alert.service";

@Component({
	selector: "kt-modifalert",
	templateUrl: "./modifalert.component.html",
	styleUrls: ["./modifalert.component.scss"],
})
export class ModifalertComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<ModifalertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private alertService: AlertService,
		private userService: UserDataService
	) {}
	user: any;
	selectedValue: string;
	list = [
		{ value: 0, viewValue: "Point collection" },
		{ value: 1, viewValue: "Signalement delivré" },
		{ value: 2, viewValue: "Signalement en cours de traitement" },
		{ value: 3, viewValue: "traité et résolu" },
		{ value: 4, viewValue: "traité et non résolu" },
	];
	ngOnInit(): void {
	}

	update(data, statut) {
		data.statutAlert = statut;
		if (statut == 0) {
			alert("Il ne faut pas changer le statut en Point de collection!");
		} else {
			this.alertService.updateAlert(data);
			this.dialogRef.close();
		}
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
}
