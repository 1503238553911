import { Login } from "./../../../../core/auth/_actions/auth.actions";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddnotificationComponent } from './addnotification/addnotification.component';
import {NotificationService} from '../../../../core/apps/notification.service';
import {UserDataService} from '../../../../core/apps/user-data.service';

@Component({
	selector: "kt-notificattions",
	templateUrl: "./notificattions.component.html",
	styleUrls: ["./notificattions.component.scss"],
})
export class NotificattionsComponent implements OnInit {
	displayedColumns = [
		"Titre",
		"Contenu",
		"Heure",
	];
	Element: any;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	selection: any;
	suggestiontab: any[];
	//annonces:any;
	constructor(
		private firestore: AngularFirestore,
		private notificationService: NotificationService,
		public dialog: MatDialog,
		private userService: UserDataService
	) {}
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<any>;
	ngOnInit() {
        this.userService.getUserByCity().subscribe(
			(data) => {
				data.map(a => {

				})
			}
		)
		this.dataSource = new MatTableDataSource([]);
			this.notificationService.getNotification().pipe(
				takeUntil(this.destroy$) // Complete & cleanup
			)
			.subscribe((data) => {
				this.dataSource.data = data;
			});
	}
	openDialogAddAnnonce() {
		let dialogRef = this.dialog.open(AddnotificationComponent, {
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			/*(val) => console.log(val)*/
			console.log("The dialog was closed");
		});
	}
	onChange(e) {
		console.log(e);
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

}
