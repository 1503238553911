import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-prevalence',
  templateUrl: './prevalence.component.html',
  styleUrls: ['./prevalence.component.scss']
})
export class PrevalenceComponent implements OnInit {

  
  prevalences= environment.prevalences;
  constructor() { }

  ngOnInit() {
  }

}
