import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AnnoncesService} from '../../../../../core/apps/annonces.service';
import {Annonces} from '../../../../../models/annonces';
import {ImageService} from '../../../../../core/apps/image.service';
import firebase from 'firebase';
class ImageSnippet {
	pending: boolean = false;
	status: string = 'init';
	constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'kt-modifier-annonce',
  templateUrl: './modifier-annonce.component.html',
  styleUrls: ['./modifier-annonce.component.scss']
})
export class ModifierAnnonceComponent implements OnInit{
	annonceForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded= false;
	idByDatesaveImage: string;
	constructor(
		public dialogRef: MatDialogRef<ModifierAnnonceComponent>,
		private formBuilder: FormBuilder,
		private annonceService: AnnoncesService,
		private imageService: ImageService,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	onNoClick() {
		if (this.fileUrl && this.fileUrl !== ''){
			const storageRef = firebase.storage().refFromURL(this.fileUrl);
			storageRef.delete().then(
				() => {
					console.log('pas insertion image , image dedleted')
				}
			)}
		this.dialogRef.close();
	}
	ngOnInit() {
		this.initForm()
	}
	initForm() {
		this.annonceForm = this.formBuilder.group({
			title: [this.data.title, [Validators.required]],
			link:[this.data.link],
			description: [this.data.description, [Validators.required]]
		});
	}
	upload(file) {
	}
	selectedFile: ImageSnippet;
	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'ok';
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'fail';
		this.selectedFile.src = '';
	}


	onSubmit(id){
		const title = this.annonceForm.get('title').value;
		const description = this.annonceForm.get('description').value;
		const linkimage = this.annonceForm.get('link').value;

        /*Delete Old image from FireStorage*/
		if (this.fileUrl && this.fileUrl !== ''){
			const storageRef = firebase.storage().refFromURL(linkimage);
			storageRef.delete().then(
				() => {
					console.log('image deleted')
				}
			)}
		const todayISOString : string = new Date().toISOString();
		const url_image = this.fileUrl && this.fileUrl !== '' ?  this.fileUrl: linkimage;
		let a ={
			id : id,
			city : localStorage.getItem('city'),
			date : todayISOString,
			description : description,
			image_url : url_image,
			title : title,
		}
		this.annonceService.updateNew(a);
	}
	processFile(imageInput: any) {
		this.fileisUploading = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);
			this.selectedFile.pending = true;
			this.idByDatesaveImage =  Date.now().toString();
			this.imageService.uploadFile(this.selectedFile.file).then(
				(url: string) => {
					this.fileUrl = url;
					this.fileisUploading = false;
					this.fileUploaded = true;
					this.onSuccess()
				},
				(err) => {
					this.onError();
				})
		});
		reader.readAsDataURL(file);
	}
}
