export const entreprise: string[] = [
	"RUSPINA",
	"RAFAHA",
	"PRIMAFISH",
	"ERRAHMA",
	"HANCHIA FISH",
	"BLUE FISH",
	"TTF : « Tunisian Teboulba Fish »",
	"TSF : « Tunisian Sea Fish »",
	"SEAMED",
	"GOLDON FISH",
	"SAS : « Société Aquaculture du Sahel »",
	"AQUASUD",
	"STEP : « Société Tunisienne d’Elevage des Poissons »",
	"PORTO FARINA",
	"CAP FARINA",
	"SEA FOOD  « HERGLA »",
	"Société POISSONS DU ROI   «  King Fish »",
	"Société  « MEDORA »",
	"Société   « Pirate Fish »",
	"Société « AQUA Fish Tunisie »",
];
export const ecloseries: string[] = [
	"Aquaculture Tunisienne",
	"SAT",
	"Aquazurra (Italie)",
	"Avanuttoria (Italie)",
	"Valle cazuliani (Italie)",
	"Pannetica (Italie)",
	"Avramar (grèce)",
	"Ferme marine du Soleil (France)",
	"Kiliç (Turquie)",
	"Akuvatur (Turquie)",
	"Nireus (Grèce)",
	"Andromeda",
	"Les poissons du soleil (France)",
	"************",
];
export const especes: string[] = [
	"Daurade royale « Sparus aurata »",
	"Loup marin «  Dicentrarchus labrax »",
	"Maigre «  Argyrosomus regius »",
	"autres",
];
export const opercules: string[] = [
	"Rien à signaler",
	"Désoperculé",
	"Malformation nette",
	"Pétéchies et hémorragies multiples",
	"Lésions traumatiques liées au transport",
	"Lésions de type traumatique",
	"Kystes de Lymphocystose",
	"Lésions spécifiques de Papillomatose",
];
export const nageoires: string[] = [
	"Rien à signaler",
	"Déchiquetées par cannibalisme",
	"Hémorragie au niveau des nageoires",
	"Hémorragies nageoires dorsales",
	"Hémorragies nageoires ventrales",
	"Hémorragies nageoires caudales",
	"Hémorragies nageoires pectorales",
	"érosion",
	"Kystes de Lymphocystose",
	"Lésions spécifiques de Papillomatose",
];
export const revetementcutanes: string[] = [
	"Rien à signaler",
	"Lésions traumatiques liées au transport",
	"Lésions de type traumatique",
	"Pétéchies et hémorragies multiples",
	"Kystes de Lymphocystose",
	"Lésions spécifiques de Papillomatose",
	"Hémorragie au niveau de la région ventrale",
	"Ulcères nombreux au niveau de nombreuses parties du corps",
	"Dispigmentation",
	"Hyperpigmentation",
];
export const embonpoints: string[] = [
	"Rien à signaler",
	"Médiocre",
	"Moyen",
	"Bon",
	"Amaigrissement prononcé",
	"Malformation nette",
	"Distension abdominale",
	"Disparité de taille nette",
];
export const bouches: string[] = [
	"Rien à signaler",
	"Lésions traumatiques liées au transport",
	"Lésions de type traumatique",
	"Erosion sévère",
	"Nécrose circonscrite jaunâtre",
	"Kystes de Lymphocystose",
	"Malformation",
	"Légère érosion",
	"Lésions spécifiques de Papillomatose",
	"Congestion",
];
export const estomacs: string[] = [
	"Rien à signaler",
	"Altération totale du tube digestif",
	"Aliment non digéré et nécrose",
	"Présence de corps étranger (plastiques, filets, ...)",
	"Légère altération",
	"Congestion sévère",
	"Anémie",
	"Nécrose totale",
	"Congestion avec présence d’un contenu blanchâtre",
	"Adhérences multiples",
];
export const intestins: string[] = [
	"Rien à signaler",
	"Altération totale du tube digestif",
	"Aliment non digéré et nécrose",
	"Présence de corps étranger (plastiques, filets, ...)",
	"Légère altération",
	"Congestion sévère",
	"Anémie",
	"Atrophie",
	"Congestion avec présence d’un contenu blanchâtre",
	"Hémorragie au niveau de la partie distale",
	"Hypertrophie de la lumière intestinale",
	"Hémorragie",
];
export const branchies: string[] = [
	"Rien à signaler",
	"Anémie totale",
	"Légère anémie",
	"Nécrose circonscrite jaunâtre",
	"Lymphocystose",
	"Cyanose",
	"Congestion généralisée",
	"Congestion circonscrite",
	"Mucus abondant",
	"Hypertrophie des lamelles branchiales",
	"Coloration jaune- marron",
	"Nécrose généralisée",
];
export const coeurs: string[] = [
	"Rien à signaler",
	"Hémorragie péricardique",
	"Légère anémie",
	"Nodules blanchâtres",
	"Friable",
	"Cyanose",
	"Congestion généralisée",
	"Hypertrophie",
	"Adhérences multiples ",
];
export const tetes: string[] = [
	"Rien à signaler",
	"Lésions traumatiques liées au transport",
	"Lésions de type traumatique",
	"érosion",
	"Kystes de Lymphocystose",
	"Lésions spécifiques de Papillomatose",
	"Malformation nette",
];
export const yeux: string[] = [
	"Rien à signaler",
	"Lésions traumatiques liées au transport",
	"énucléation",
	"Exophtalmie unilatérale",
	"Exophtalmie bilatérale",
	"Cataracte",
	"Malformation",
	"Inflammation nette",
	"Hémorragie",
	"Congestion",
];
export const cerveaux: string[] = [
	"Rien à signaler",
	"Congestion sévère",
	"Légère congestion",
	"Nécrose totale",
	"Malformation",
	"Inflammation nette",
	"Hémorragie",
];
export const rates: string[] = [
	"Rien à signaler",
	"Hypertrophie et présence de granulomes blanc-grisâtres",
	"Légère anémie",
	"Nodules blanchâtres  de forme caséeux",
	"Friable",
	"Atrophie",
	"Congestion généralisée",
	"Hypertrophie",
	"Adhérences multiples",
	"Présence de nodules multiples",
];
export const foies: string[] = [
	"Rien à signaler",
	"Congestion localisée",
	"Légère anémie",
	"Nodules blanc- grisâtre",
	"Friable",
	"Pâle",
	"Congestion généralisée",
	"Hypertrophie",
	"Adhérences multiples",
];
export const vesiculebiliaires: string[] = [
	"Rien à signaler",
	"Remplie de bile signe d’anorexie",
	"Contenue vert - noirâtre",
	"Nodules blanc- grisâtre",
	"Friable",
	"Pâle",
	"Atrophie",
	"Congestion généralisée",
	"Hypertrophie",
	"Adhérences multiples",
	"Inflammation",
];
export const reins: string[] = [
	"Rien à signaler",
	"Hypertrophie et présence de granulomes blanc-grisâtres",
	"Légère anémie",
	"Nodules jaunâtres  de forme caséeux",
	"Friable",
	"Nécrose et congestion",
	"Congestion généralisée",
	"Hypertrophie",
	"Adhérences multiples",
	"Lésions de granulomatose",
	"Présence de nodules multiples",
];
export const vessienatatoires: string[] = [
	"Rien à signaler",
	"Distendue",
	"Gonflement",
	"Adhérence",
	"Malformation",
	"Inflammation nette",
];
export const prevalences: string[] = [
	"Faible prévalence (+)",
	"Prévalence moyenne (++)",
	"Forte prévalence (+++)",
	"Prévalence suraigüe  (++++)",
];
export const formes: string[] = [
	"Présence (+)",
	"Forme subaigüe (++)",
	"Forte aigue (+++)",
	"Forme suraigüe  (++++)",
	"Forme chronique",
];
export const molecules: string[] = [
	"Fluméquine",
	"Florfenicol",
	"Trimetoprime - Sulfadiazine",
	"Oxytetracycline",
	"Enrofloxacine",
	"Doxycycline",
	"Amoxicilline",
	"Vitamine C",
	"Forcivitol",
];
export const dureetraitements: string[] = [
	"5 jours",
	"6 jours",
	"7 jours",
	"8 jours",
	"9 jours",
	"10 jours",
	"11 jours",
	"12 jours",
	"3 à 5 jours",
	"5 à 8 jours",
	"8 à 10 jours",
];
export const incidents: string[] = [
	"Rien à signaler",
	"Contrôle à la réception d’alevins",
	"Mortalité massive",
	"Mortalité ascendante",
	"Mortalité faible mais constante",
	"Mortalité variable (n’est pas constante)",
	"Contrôle qualité produit fini",
	"Contrôle de routine (à titre préventif)",
	"Contrôle après traitement",
	"Symptômes et/ou comportements anormaux des poissons",
	"Lésions anormales détectées",
	"Mauvaise manipulation",
	"**************",
];
export const frequences: string[] = [
	"+",
	"++",
	"+++",
	"++++",
	"Très faible",
];
export const severite: string[] = [
	"+",
	"++",
	"+++",
	"++++",
	"Très faible",
];
export const environment = {
	production: true,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	entreprise:entreprise,
	frequences:frequences,
	severite:severite,
	incidents:incidents,
	ecloseries:ecloseries,
	especes:especes,
	embonpoints:embonpoints,
	revetementcutanes:revetementcutanes,
	nageoires:nageoires,
	opercules:opercules,
	bouches:bouches,
	estomacs :estomacs,
	intestins :intestins,
	branchies :branchies,
	coeurs :coeurs,
	tetes :tetes,
	yeux :yeux,
	cerveaux :cerveaux,
	rates :rates,
	foies :foies,
	vesiculebiliaires :vesiculebiliaires,
	reins :reins,
	vessienatatoires :vessienatatoires,
	prevalences :prevalences,
	formes :formes,
	molecules :molecules,
	dureetraitements :dureetraitements,
};
