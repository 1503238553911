import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-severite',
  templateUrl: './severite.component.html',
  styleUrls: ['./severite.component.scss']
})
export class SeveriteComponent implements OnInit {

  severite= environment.severite;
  constructor() { }

  ngOnInit() {
  }

}
