import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-foie',
  templateUrl: './foie.component.html',
  styleUrls: ['./foie.component.scss']
})
export class FoieComponent implements OnInit {

  
  foies= environment.foies;
  constructor() { }

  ngOnInit() {
  }

}
