import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { FileUpload } from "../../models/fileUpload";
import firebase from 'firebase';
@Injectable({
	providedIn: "root",
})
export class ImageService {
	private basePath = "/alerts";
	constructor(private db: AngularFireDatabase) {}

	getFileUploads(numberItems): AngularFireList<FileUpload> {
		return this.db.list(this.basePath, (ref) =>
			ref.limitToLast(numberItems)
		);
	}

	/*	constructor(private http: http) {
	}


	public uploadImage(image: File): Observable<Response> {
		const formData = new FormData();

		formData.append('image', image);

		return this.http.post('/api/v1/image-upload', formData);
	}*/
	uploadFile(file: File) {
		return new Promise(
			(resolve, reject) => {
				const almostUniqueFileName = Date.now().toString();
				const upload = firebase.storage().ref()
					.child('news/' + almostUniqueFileName +"/" + file.name).put(file);
				upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
					() => {
						console.log('Chargement…');
					},
					(error) => {
						console.log('Erreur de chargement ! : ' + error);
						reject();
					},
					() => {
						resolve(upload.snapshot.ref.getDownloadURL());
					}
				);
			}
		);
	}
	uploadDoc(file: File) {
		return new Promise(
			(resolve, reject) => {
				const almostUniqueFileName = Date.now().toString();
				const upload = firebase.storage().ref()
					.child('docs/' + almostUniqueFileName +"/" + file.name).put(file);
				upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
					() => {
						console.log('Chargement…');
					},
					(error) => {
						console.log('Erreur de chargement ! : ' + error);
						reject();
					},
					() => {
						resolve(upload.snapshot.ref.getDownloadURL());
					}
				);
			}
		);
	}
	uploadImage(file: File) {
		return new Promise(
			(resolve, reject) => {
				const almostUniqueFileName = Date.now().toString();
				const upload = firebase.storage().ref()
					.child('images/' + almostUniqueFileName +"/" + file.name).put(file);
				upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
					() => {
						console.log('Chargement…');
					},
					(error) => {
						console.log('Erreur de chargement ! : ' + error);
						reject();
					},
					() => {
						resolve(upload.snapshot.ref.getDownloadURL());
					}
				);
			}
		);
	}
}
