import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnnoncesComponent } from "./annonces/annonces.component";
import { RouterModule, Routes } from "@angular/router";
import { NotificattionsComponent } from "./notificattions/notificattions.component";
import { PartialsModule } from "../../partials/partials.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatToolbarModule,
	MatFormFieldModule,
} from "@angular/material";
import { SuggestionComponent } from "./suggestion/suggestion.component";
import { AlertComponent } from "./alert/alert.component";
import { DialogComponent } from "./dialog/dialog.component";
import { AddAnnonceComponent } from "./annonces/add-annonce/add-annonce.component";
import { SuggestionDialogComponent } from "./suggestion/suggestion-dialog/suggestion-dialog.component";
import { MentionlegalComponent } from "./mentionlegal/mentionlegal.component";
import { ModifierAnnonceComponent } from "./annonces/modifier-annonce/modifier-annonce.component";

import { AddnotificationComponent } from "./notificattions/addnotification/addnotification.component";
import { ModifsuggestionComponent } from "./suggestion/modifsuggestion/modifsuggestion.component";
import { ModifalertComponent } from "./alert/modifalert/modifalert.component";
import { ImagealertComponent } from "./alert/imagealert/imagealert.component";
import { DocsComponent } from "./docs/docs.component";
import { AdddocComponent } from "./docs/adddoc/adddoc.component";
import { UpdatedocComponent } from "./docs/updatedoc/updatedoc.component";
import { ViewdocComponent } from "./docs/viewdoc/viewdoc.component";
import { EntrepriseComponent } from "./entreprise/entreprise.component";
import { EcloseriesComponent } from "./ecloseries/ecloseries.component";
import { EspecesComponent } from "./especes/especes.component";
import { EmbonpointComponent } from "./embonpoint/embonpoint.component";
import { RevetementcutaneComponent } from "./revetementcutane/revetementcutane.component";
import { NageoiresComponent } from "./nageoires/nageoires.component";
import { OperculeComponent } from "./opercule/opercule.component";
import { EstomacComponent } from "./estomac/estomac.component";
import { BoucheComponent } from "./bouche/bouche.component";
import { IntestinComponent } from "./intestin/intestin.component";
import { BranchieComponent } from "./branchie/branchie.component";
import { CoeurComponent } from "./coeur/coeur.component";
import { TeteComponent } from "./tete/tete.component";
import { YeuxComponent } from "./yeux/yeux.component";
import { CerveauComponent } from "./cerveau/cerveau.component";
import { RateComponent } from "./rate/rate.component";
import { FoieComponent } from "./foie/foie.component";
import { VesiculebiliaireComponent } from "./vesiculebiliaire/vesiculebiliaire.component";
import { ReinComponent } from "./rein/rein.component";
import { VessienatatoireComponent } from "./vessienatatoire/vessienatatoire.component";
import { AutreexameninterneComponent } from "./autreexameninterne/autreexameninterne.component";
import { PrevalenceComponent } from "./prevalence/prevalence.component";
import { FormeComponent } from "./forme/forme.component";
import { MoleculesComponent } from "./molecules/molecules.component";
import { DureetraitementComponent } from "./dureetraitement/dureetraitement.component";
import { ConduiteComponent } from "./conduite/conduite.component";
import { AddconduiteComponent } from "./conduite/addconduite/addconduite.component";
import { DiagnosticComponent } from "./diagnostic/diagnostic.component";
import { AdddiagnosticComponent } from "./diagnostic/adddiagnostic/adddiagnostic.component";
import { ExamenComponent } from "./examen/examen.component";
import { AddexamenComponent } from "./examen/addexamen/addexamen.component";
import { IncidentComponent } from "./incident/incident.component";
import { AddincidentComponent } from "./incident/addincident/addincident.component";
import { FrequencesComponent } from "./frequences/frequences.component";
import { SeveriteComponent } from "./severite/severite.component";
import { AddautreComponent } from "./autreexameninterne/addautre/addautre.component";
import { ViewexamenComponent } from "./examen/viewexamen/viewexamen.component";
import { RecherchebacteriologiqueComponent } from "./recherchebacteriologique/recherchebacteriologique.component";
import { AddrecherchebacteriologiqueComponent } from "./recherchebacteriologique/addrecherchebacteriologique/addrecherchebacteriologique.component";
import { RechercheparasitaireComponent } from "./rechercheparasitaire/rechercheparasitaire.component";
import { AddrechercheparasitaireComponent } from "./rechercheparasitaire/addrechercheparasitaire/addrechercheparasitaire.component";
import { MatTableExporterModule } from "mat-table-exporter";
import { ImagesComponent } from './images/images.component';
import { ShowimagesComponent } from './examen/showimages/showimages.component';

const routes: Routes = [
	{ path: "annonces", component: AnnoncesComponent },
	{ path: "bacteriologique", component: RecherchebacteriologiqueComponent },
	{ path: "parasitaire", component: RechercheparasitaireComponent },
	{ path: "examen", component: ExamenComponent },
	{ path: "images", component: ImagesComponent },
	{ path: "incident", component: IncidentComponent },
	{ path: "autres", component: AutreexameninterneComponent },
	{ path: "frequences", component: FrequencesComponent },
	{ path: "severite", component: SeveriteComponent },
	{ path: "entreprise", component: EntrepriseComponent },
	{ path: "diagnostic", component: DiagnosticComponent },
	{ path: "conduite", component: ConduiteComponent },
	{ path: "ecloseries", component: EcloseriesComponent },
	{ path: "especes", component: EspecesComponent },
	{ path: "embonpoint", component: EmbonpointComponent },
	{ path: "revetementcutane", component: RevetementcutaneComponent },
	{ path: "nageoires", component: NageoiresComponent },
	{ path: "opercule", component: OperculeComponent },
	{ path: "bouche", component: BoucheComponent },
	{ path: "estomac", component: EstomacComponent },
	{ path: "intestin", component: IntestinComponent },
	{ path: "branchie", component: BranchieComponent },
	{ path: "coeur", component: CoeurComponent },
	{ path: "tete", component: TeteComponent },
	{ path: "yeux", component: YeuxComponent },
	{ path: "cerveau", component: CerveauComponent },
	{ path: "rate", component: RateComponent },
	{ path: "foie", component: FoieComponent },
	{ path: "vesiculebiliaire", component: VesiculebiliaireComponent },
	{ path: "rein", component: ReinComponent },
	{ path: "vessienatatoire", component: VessienatatoireComponent },
	{ path: "autreexameninterne", component: AutreexameninterneComponent },
	{ path: "prevalence", component: PrevalenceComponent },
	{ path: "forme", component: FormeComponent },
	{ path: "molecules", component: MoleculesComponent },
	{ path: "dureetraitement", component: DureetraitementComponent },
	{ path: "docs", component: DocsComponent },
];
@NgModule({
	declarations: [
		AnnoncesComponent,
		NotificattionsComponent,
		SuggestionComponent,
		AlertComponent,
		DialogComponent,
		AddAnnonceComponent,
		SuggestionDialogComponent,
		MentionlegalComponent,
		ModifierAnnonceComponent,
		AddnotificationComponent,
		ModifsuggestionComponent,
		ModifalertComponent,
		ImagealertComponent,
		DocsComponent,
		AdddocComponent,
		UpdatedocComponent,
		ViewdocComponent,
		EntrepriseComponent,
		EcloseriesComponent,
		EspecesComponent,
		EmbonpointComponent,
		RevetementcutaneComponent,
		NageoiresComponent,
		OperculeComponent,
		EstomacComponent,
		BoucheComponent,
		IntestinComponent,
		BranchieComponent,
		CoeurComponent,
		TeteComponent,
		YeuxComponent,
		CerveauComponent,
		RateComponent,
		FoieComponent,
		VesiculebiliaireComponent,
		ReinComponent,
		VessienatatoireComponent,
		AutreexameninterneComponent,
		PrevalenceComponent,
		FormeComponent,
		MoleculesComponent,
		DureetraitementComponent,
		ConduiteComponent,
		AddconduiteComponent,
		DiagnosticComponent,
		AdddiagnosticComponent,
		ExamenComponent,
		AddexamenComponent,
		IncidentComponent,
		AddincidentComponent,
		FrequencesComponent,
		SeveriteComponent,
		AddautreComponent,
		ViewexamenComponent,
		RecherchebacteriologiqueComponent,
		AddrecherchebacteriologiqueComponent,
		RechercheparasitaireComponent,
		AddrechercheparasitaireComponent,
		ImagesComponent,
		ShowimagesComponent,
	],
	imports: [
		MatTableExporterModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatToolbarModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		RouterModule.forChild(routes),
		
	],
})
export class AppsModule {}
