import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {Annonces} from '../../models/annonces';
import {Observable} from 'rxjs';
import {User} from '../../models/user';

@Injectable({
	providedIn: "root",
})
export class UserDataService {
	id: string = "";
	tab :any
	constructor(private firestore: AngularFirestore) {}

	getusers() {
		return this.firestore.collection("users").valueChanges();
	}
	getuserById(id: string): Observable<any> {
		//this.id = id;
		return this.firestore
			.collection("users")
      .doc(id)
      .valueChanges();
	}

	getUserByCity():Observable<any>{
		return this.firestore.collection("users" ,(ref) =>
		ref.where("city", "==", localStorage.getItem("city"))
		).valueChanges()



	}
}
