import { element } from "protractor";

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
	MatDialog,
	MatPaginator,
	MatSort,
	MatTableDataSource,
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, Subject } from "rxjs";
import { AddAnnonceComponent } from "../annonces/add-annonce/add-annonce.component";
import { SuggestionDialogComponent } from "./suggestion-dialog/suggestion-dialog.component";
import { Suggestions } from "../../../../models/suggestions";
import { User } from "../../../../models/user";
import { UserDataService } from "../../../../core/apps/user-data.service";
import { SuggestionService } from "../../../../core/apps/suggestion.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { ModifsuggestionComponent } from "./modifsuggestion/modifsuggestion.component";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
	selector: "kt-suggestion",
	templateUrl: "./suggestion.component.html",
	styleUrls: ["./suggestion.component.scss"],
})
export class SuggestionComponent implements OnInit {
	displayedColumns = [
		"select",
		"Catégorie",
		"Date",
		"Nature",
		"detail",
		"Statut",
	];
	selection = new SelectionModel<Suggestions>(false, []);
	Element: any;
	suggestion: Suggestions;
	suggestiontab = [];
	docDefinition;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	//annonces:any;
	constructor(
		private firestore: AngularFirestore,
		public dialog: MatDialog,
		private userService: UserDataService,
		private suggestionService: SuggestionService
	) {}
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<any>;
	tabGetByCity = [];
	a;
	ngOnInit() {
		/*let user = this.suggestionService
			.getlist()
			.subscribe((a) =>
			console.log(a)
			);*/

		this.dataSource = new MatTableDataSource([]);
		let sub = this.suggestionService
			.getListSuggestions()
			.subscribe((actions) => {
				this.suggestiontab = [];
				return actions.map((a) => {
					let data = a.payload.doc.data() as Suggestions;
					data.id = a.payload.doc.id;
					let annonceToString = JSON.stringify(
						Object.assign({}, data)
					);
					let stringToJsonObject = JSON.parse(annonceToString);
					this.suggestion = stringToJsonObject;
					this.suggestion.status == null
						? (this.suggestion.status = "0")
						: this.suggestion.status;
					this.suggestion.datemodif == null
						? (this.suggestion.datemodif = null)
						: this.suggestion.datemodif;
					this.suggestiontab.push(this.suggestion);
					this.dataSource.data = this.suggestiontab;
				});
			});
	}
	onChange(e) {
		console.log(e);
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
	}
	openDialog(element) {
		let dialogRef = this.dialog.open(SuggestionDialogComponent, {
			data: { contenu: element.contenu },
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			/*(val) => console.log(val)*/
			console.log("The dialog was closed");
		});
	}
	openDialogStatut(element) {
		let dialogRef = this.dialog.open(ModifsuggestionComponent, {
			data: {
				id: element.id,
				contenu: element.contenu,
				nature: element.nature,
				category: element.category,
				status: parseInt(element.status),
				datemodif: element.datemodif,
				user_uid: element.user_uid,
			},
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			/*(val) => console.log(val)*/
			console.log("The dialog was closed");
		});
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	pdf(selection) {
		this.suggestiontab = [];
		this.userService
			.getuserById(selection[0].user_uid)
			.subscribe((values: User) => {
				this.suggestion = new Suggestions(
					selection[0].category,
					selection[0].contenu,
					selection[0].nature,
					values,
					selection[0].date == null
						? "Pas de date "
						: selection[0].date,
					selection[0].datemodif == null
						? "Pas de datemodif "
						: selection[0].datemodif,
					selection[0].statut == null ? 0 : selection[0].statut
				);
				let docDefinition = {
					content: [
						{
							text: this.suggestion.date,
							bold: true,
						},
						{
							text: "Catégorie de la correspondance",
							bold: true,
							alignment: "center",
						},
						{ text: " " },
						{
							text: this.suggestion.category,
							alignment: "center",
							fontSize: 20,
						},
						{ text: " " },
						{ text: " " },
						{
							text: "Coordonnées du citoyen",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },
						{ text: this.suggestion.user.name },
						{ text: this.suggestion.user.phoneNumber },
						{ text: " " },
						{ text: " " },
						{
							text: "Object de la correspondance",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },
						{ text: this.suggestion.nature },
						{ text: " " },
						{ text: " " },
						{
							text: "Contenu de la correspondance",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },

						{ text: this.suggestion.contenu },
					],
				};
				pdfMake
					.createPdf(docDefinition)
					.download("correspondance de " + this.suggestion.user.name);
			});
	}
}

export interface Element {
	Titre: string;
	Date: number;
}

/*for(let i=0; i< this.annonces.length; i++){
	Element[i].title = this.annonces[i].title;
	Element[i].commune = this.annonces[i].commune;
	Element[i].date = this.annonces[i].date;
}*/
const ELEMENT_DATA: Element[] = [];
