import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-embonpoint',
  templateUrl: './embonpoint.component.html',
  styleUrls: ['./embonpoint.component.scss']
})
export class EmbonpointComponent implements OnInit {

  embonpoints= environment.embonpoints;
  constructor() { }

  ngOnInit() {
  }

}
