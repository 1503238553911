import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DocsService } from "../../../../../core/apps/docs.service";
import { ImageService } from "../../../../../core/apps/image.service";
import firebase from "firebase";
import { DomSanitizer } from '@angular/platform-browser';

class ImageSnippet {
	pending: boolean = false;
	status: string = "init";
	constructor(public src: string, public file: File) {}
}
@Component({
	selector: "kt-updatedoc",
	templateUrl: "./updatedoc.component.html",
	styleUrls: ["./updatedoc.component.scss"],
})
export class UpdatedocComponent implements OnInit {
	docForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
  idByDatesaveImage: string;
  list = [
		{  doc_type: "other" },
		{ doc_type: "formulaire" },
	];
	constructor(
		public dialogRef: MatDialogRef<UpdatedocComponent>,
		private formBuilder: FormBuilder,
		private docService: DocsService,
    private imageService: ImageService,
    public sanitizer: DomSanitizer, 
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	onNoClick() {
		if (this.fileUrl && this.fileUrl !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		this.dialogRef.close();
	}
	ngOnInit() {
		this.initForm();
	}
	initForm() {
		this.docForm = this.formBuilder.group({
			doc_name: [this.data.doc_name, [Validators.required]],
			doc_url: [this.data.doc_url],
			doc_type: [this.data.doc_type, [Validators.required]],
		});
	}
	upload(file) {}
	selectedFile: ImageSnippet;
	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "ok";
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "fail";
		this.selectedFile.src = "";
	}

	onSubmit(id) {
		const doc_name = this.docForm.get("doc_name").value;
		const doc_type = this.docForm.get("doc_type").value;
		const doc_url = this.docForm.get("doc_url").value;

		/*Delete Old image from FireStorage*/
		if (this.fileUrl && this.fileUrl !== "") {
			const storageRef = firebase.storage().refFromURL(doc_url);
			storageRef.delete().then(() => {
				console.log("image deleted");
			});
		}
		const todayISOString: string = new Date().toISOString();
		const url_image =
			this.fileUrl && this.fileUrl !== "" ? this.fileUrl : doc_url;
		let a = {
			id: id,
			city: localStorage.getItem("city"),
			doc_type: doc_type,
			doc_url: url_image,
			doc_name: doc_name,
		};
		this.docService.updatedoc(a);
	}
	processFile(imageInput: any) {
		this.fileisUploading = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		reader.addEventListener("load", (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);
			this.selectedFile.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadDoc(this.selectedFile.file).then(
				(url: string) => {
					this.fileUrl = url;
					this.fileisUploading = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
}
