import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-bouche',
  templateUrl: './bouche.component.html',
  styleUrls: ['./bouche.component.scss']
})
export class BoucheComponent implements OnInit {

  bouches= environment.bouches;
  constructor() { }

  ngOnInit() {
  }

}
