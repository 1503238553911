import { AddexamenComponent } from "./views/pages/apps/examen/addexamen/addexamen.component";
import { SuggestionDialogComponent } from "./views/pages/apps/suggestion/suggestion-dialog/suggestion-dialog.component";
// Angular
import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
	GestureConfig,
	MatProgressSpinnerModule,
	MatTableModule,
} from "@angular/material";
import { OverlayModule } from "@angular/cdk/overlay";
// Angular in memory
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
// Perfect Scroll bar
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
// Env
import { environment } from "../environments/environment";
// Hammer JS
import "hammerjs";
// NGX Permissions
import { NgxPermissionsModule } from "ngx-permissions";
// NGRX
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// State
import { metaReducers, reducers } from "./core/reducers";
// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Layout Services
import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService,
} from "./core/_base/layout";
// Auth
import { AuthModule } from "./views/pages/auth/auth.module";
import { AuthService } from "./core/auth";
// CRUD
import {
	HttpUtilsService,
	LayoutUtilsService,
	TypesUtilsService,
} from "./core/_base/crud";
// Config
import { LayoutConfig } from "./core/_config/layout.config";
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from "ngx-highlightjs";
import * as typescript from "highlight.js/lib/languages/typescript";
import * as scss from "highlight.js/lib/languages/scss";
import * as xml from "highlight.js/lib/languages/xml";
import * as json from "highlight.js/lib/languages/json";
import { AppsModule } from "./views/pages/apps/apps.module";
import { AngularFirestore, AngularFirestoreModule } from "@angular/fire/firestore";
import * as firebase from "firebase";
import { AngularFireModule } from "@angular/fire";
import { DialogComponent } from "./views/pages/apps/dialog/dialog.component";
import { AddAnnonceComponent } from "./views/pages/apps/annonces/add-annonce/add-annonce.component";
import { ModifierAnnonceComponent } from "./views/pages/apps/annonces/modifier-annonce/modifier-annonce.component";

import { AddnotificationComponent } from "./views/pages/apps/notificattions/addnotification/addnotification.component";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { ModifsuggestionComponent } from "./views/pages/apps/suggestion/modifsuggestion/modifsuggestion.component";
import { ModifalertComponent } from "./views/pages/apps/alert/modifalert/modifalert.component";
import { ImagealertComponent } from "./views/pages/apps/alert/imagealert/imagealert.component";
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AsyncPipe} from '@angular/common';
import {NotificationService} from './core/apps/notification.service';
import { AdddocComponent } from './views/pages/apps/docs/adddoc/adddoc.component';
import { UpdatedocComponent } from './views/pages/apps/docs/updatedoc/updatedoc.component';
import { ViewdocComponent } from './views/pages/apps/docs/viewdoc/viewdoc.component';
import { AddconduiteComponent } from "./views/pages/apps/conduite/addconduite/addconduite.component";
import { AdddiagnosticComponent } from "./views/pages/apps/diagnostic/adddiagnostic/adddiagnostic.component";
import { AddincidentComponent } from "./views/pages/apps/incident/addincident/addincident.component";
import { AddautreComponent } from "./views/pages/apps/autreexameninterne/addautre/addautre.component";
import { ViewexamenComponent } from "./views/pages/apps/examen/viewexamen/viewexamen.component";
import { AddrecherchebacteriologiqueComponent } from "./views/pages/apps/recherchebacteriologique/addrecherchebacteriologique/addrecherchebacteriologique.component";
import { AddrechercheparasitaireComponent } from "./views/pages/apps/rechercheparasitaire/addrechercheparasitaire/addrechercheparasitaire.component";
import { AngularFireStorageModule } from "angularfire2/storage";
import { ShowimagesComponent } from "./views/pages/apps/examen/showimages/showimages.component";

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: "typescript", func: typescript },
		{ name: "scss", func: scss },
		{ name: "xml", func: xml },
		{ name: "json", func: json },
	];
}
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
	apiKey: "AIzaSyB5HfftJebvIXNDgDHfVYoyJCg_PcuRXL8",
	authDomain: "majdi-aa1c1.firebaseapp.com",
	databaseURL: "https://majdi-aa1c1-default-rtdb.firebaseio.com/",
	projectId: "majdi-aa1c1",
	storageBucket: "majdi-aa1c1.appspot.com",
	messagingSenderId: "20728533384",
	appId: "1:20728533384:web:7fca139f3ec76fc427aa3d",
	measurementId: "G-1416GCELPX"
};
firebase.initializeApp(config);
firebase.analytics();
@NgModule({
	declarations: [
		AppComponent,
		//AlertsComponent,
		//ClaimsComponent,
		//CorrespondancesComponent,
		//IndicatorsComponent,
		//NewsComponent,
		//NotificationsComponent,
	],

	entryComponents: [
		DialogComponent,
		AddAnnonceComponent,
		SuggestionDialogComponent,
		AddnotificationComponent,
		ModifierAnnonceComponent,
		ModifsuggestionComponent,
		ModifalertComponent,
		ImagealertComponent,
		UpdatedocComponent,
		AdddocComponent,
		ViewdocComponent,
		AddconduiteComponent,
		AdddiagnosticComponent,
		AddexamenComponent,
		AddincidentComponent,
		AddautreComponent,
		ViewexamenComponent,
		AddrecherchebacteriologiqueComponent,
		AddrechercheparasitaireComponent,
		ShowimagesComponent

	],

	imports: [
		AngularFirestoreModule, AngularFireStorageModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
					passThruUnknownUrl: true,
					dataEncapsulation: false,
			  })
			: [],
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,
		AppsModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(config),
		//AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
	],
	exports: [],
	providers: [
		AuthService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig,
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages },
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		NotificationService,
		AsyncPipe
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
