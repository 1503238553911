import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-imagealert',
  templateUrl: './imagealert.component.html',
  styleUrls: ['./imagealert.component.scss']
})
export class ImagealertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImagealertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
	onNoClick(): void {
		this.dialogRef.close();
	}
}
