import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ImageService} from '../../../../../core/apps/image.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Annonces} from '../../../../../models/annonces';
import firebase from 'firebase';
import {AnnoncesService} from '../../../../../core/apps/annonces.service';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

class ImageSnippet {
	pending: boolean = false;
	status: string = 'init';
	constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'kt-add-annonce',
  templateUrl: './add-annonce.component.html',
  styleUrls: ['./add-annonce.component.scss']
})
export class AddAnnonceComponent implements OnInit {
	progress: number;
	annonceForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded= false;
	idByDatesaveImage: string;

	constructor( public dialogRef: MatDialogRef<AddAnnonceComponent>,
				 private formBuilder: FormBuilder,
				 private imageService: ImageService,
				 private annonceService: AnnoncesService) {

	}

	ngOnInit() {
		this.initForm()
	}
	onNoClick() {
		if (this.fileUrl && this.fileUrl !== ''){
		const storageRef = firebase.storage().refFromURL(this.fileUrl);
		storageRef.delete().then(
			() => {
				console.log('pas insertion image , image dedleted')
			}
		)}
		this.dialogRef.close();
	}

	upload(file) {
	}
	selectedFile: ImageSnippet;

	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'ok';
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'fail';
		this.selectedFile.src = '';
	}


	initForm() {
		this.annonceForm = this.formBuilder.group({
			title: ['', [Validators.required]],
			description: ['', [Validators.required]]
		});
	}
   onSubmit(){
	   const title = this.annonceForm.get('title').value;
	   const description = this.annonceForm.get('description').value;
	   const todayISOString : string = new Date().toISOString();
	   const url_image = this.fileUrl && this.fileUrl !== '' ?  this.fileUrl: '';
       const newAnnonce = new Annonces(localStorage.getItem('city'),todayISOString, description, url_image,title)
       this.annonceService.saveAnnonce(newAnnonce).then( res => {
	  });
   }

	processFile(imageInput: any) {
		this.fileisUploading = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();

		reader.addEventListener('load', (event: any) => {

			this.selectedFile = new ImageSnippet(event.target.result, file);

			this.selectedFile.pending = true;
			this.idByDatesaveImage =  Date.now().toString();
			this.imageService.uploadFile(this.selectedFile.file).then(
				(url: string) => {
					this.fileUrl = url;
					this.fileisUploading = false;
					this.fileUploaded = true;
					this.onSuccess()
				},
				(err) => {
					this.onError();
				})
		});
		reader.readAsDataURL(file);
	}
}
