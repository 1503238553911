import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DialogComponent } from "../../dialog/dialog.component";

@Component({
	selector: "kt-suggestion-dialog",
	templateUrl: "./suggestion-dialog.component.html",
	styleUrls: ["./suggestion-dialog.component.scss"],
})
export class SuggestionDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<SuggestionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
