import { Injectable } from "@angular/core";
import { Annonces } from "../../models/annonces";
import { Observable, Subject } from "rxjs";
import * as firebase from "firebase";
import DataSnapshot = firebase.database.DataSnapshot;
import {
	AngularFirestore,
	AngularFirestoreCollection,
} from "@angular/fire/firestore";

@Injectable({
	providedIn: "root",
})
export class AnnoncesService {
	annonces: Annonces[] = [];
	annoncesSubject = new Subject<Annonces[]>();
	id: string = "";
	constructor(private firestore: AngularFirestore) {}

	emitAnnonce() {
		this.annoncesSubject.next(this.annonces);
	}

	getListAnnonces() {
		return this.firestore
			.collection("News", (ref) => ref.where("city",'==', localStorage.getItem("city")))
			.snapshotChanges();
	}
	updateNew(data) {
		this.id = data.id;
		return this.firestore.collection("News").doc(this.id).set({
			city: data.city,
			date: data.date,
			description: data.description,
			image_url: data.image_url,
			title: data.title,
		});
	}
	saveAnnonce(annonce: Annonces): Promise<Annonces> {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("News/")
				.add({
					city: annonce.city,
					date: annonce.date,
					description: annonce.description,
					image_url: annonce.image_url,
					title: annonce.title,
				})
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
}
