import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-nageoires',
  templateUrl: './nageoires.component.html',
  styleUrls: ['./nageoires.component.scss']
})
export class NageoiresComponent implements OnInit {

  nageoires= environment.nageoires;
  constructor() { }

  ngOnInit() {
  }

}
