import { Examen } from "./../../../../../models/examen";
import { environment } from "./../../../../../../environments/environment.prod";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Autres } from "../../../../../models/autres";
import { ConduiteService } from "../../../../../core/apps/conduite.service";
import { database } from "firebase";
import { ImageService } from "../../../../../core/apps/image.service";
import firebase from "firebase";
import { Observable } from 'rxjs';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFirestore } from 'angularfire2/firestore';
import { finalize } from 'rxjs/operators';
class ImageSnippet {
	pending: boolean = false;
	status: string = "init";
	constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'kt-addexamen',
  templateUrl: './addexamen.component.html',
  styleUrls: ['./addexamen.component.scss']
})
export class AddexamenComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileisUploading1 = false;
	fileisUploading2 = false;
	fileisUploading3 = false;
	fileisUploading4 = false;
	fileUrl: string;
	fileUrl1: string;
	fileUrl2: string;
	fileUrl3: string;
	fileUrl4: string;
	fileUploaded = false;
	idByDatesaveImage: string;

	typeoperation;
	selectedValue: string ="";
  aujourdhui= new Date();
  entreprises= environment.entreprise;
  especes= environment.especes;
  ecloseries= environment.ecloseries;
  incidents= environment.incidents;
  embonpoints= environment.embonpoints;
  frequences= environment.frequences;
  severite= environment.severite;
  revetements= environment.revetementcutanes;
  nageoires= environment.nageoires;
  opercules= environment.opercules;
  bouches= environment.bouches;
  estomacs= environment.estomacs;
  intestins= environment.intestins;
  branchies= environment.branchies;
  coeurs= environment.coeurs;
  tetes= environment.tetes;
  yeux= environment.yeux;
  cerveaux= environment.cerveaux;
  rates= environment.rates;
  foies= environment.foies;
  vesiculeBiliaires= environment.vesiculebiliaires;
  reins= environment.reins;
  vessienatatoires= environment.vessienatatoires;
  molecules= environment.molecules;
  dureetraitements= environment.dureetraitements;
  formes= environment.formes;
 // autres;
  autre: Autres;
	autres;
  diagnostics;
  conduites;
  bacteriologiques;
  parasitaires;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  selectedFilefile: FileList | null;
  multiplefile: number = 0;
  constructor(
    public dialogRef: MatDialogRef<AddexamenComponent>,
    private formBuilder: FormBuilder,
    private TripService: ConduiteService,
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private storage: AngularFireStorage, 
     private afs: AngularFirestore,
  ) { }
  ngOnInit() {    
  this.autres =this.data.autres;
  this.diagnostics =this.data.diagnostics;
  this.conduites =this.data.conduites;
  this.bacteriologiques =this.data.bacteriologiques;
  this.parasitaires =this.data.parasitaires;
      this.initForm();
  }

  onNoClick() {
		if (this.fileUrl && this.fileUrl !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		if (this.fileUrl1 && this.fileUrl1 !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl1);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		if (this.fileUrl2 && this.fileUrl2 !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl2);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		if (this.fileUrl3 && this.fileUrl3 !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl3);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		if (this.fileUrl4 && this.fileUrl4 !== "") {
			const storageRef = firebase.storage().refFromURL(this.fileUrl4);
			storageRef.delete().then(() => {
				console.log("pas insertion document  , docuement dedleted");
			});
		}
		this.dialogRef.close();
    this.multiplefile =0;
	}
  upload(file) {}
	selectedFile: ImageSnippet;
	selectedFile1: ImageSnippet;
	selectedFile2: ImageSnippet;
	selectedFile3: ImageSnippet;
	selectedFile4: ImageSnippet;
  clickEvent(){
    this.multiplefile++;
  }
	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "ok";
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = "fail";
		this.selectedFile.src = "";
	}
	private onSuccess1() {
		this.selectedFile1.pending = false;
		this.selectedFile1.status = "ok";
	}

	private onError1() {
		this.selectedFile1.pending = false;
		this.selectedFile1.status = "fail";
		this.selectedFile1.src = "";
	}
	private onSuccess2() {
		this.selectedFile2.pending = false;
		this.selectedFile2.status = "ok";
	}

	private onError2() {
		this.selectedFile2.pending = false;
		this.selectedFile2.status = "fail";
		this.selectedFile2.src = "";
	}
	private onSuccess3() {
		this.selectedFile3.pending = false;
		this.selectedFile3.status = "ok";
	}

	private onError3() {
		this.selectedFile3.pending = false;
		this.selectedFile3.status = "fail";
		this.selectedFile3.src = "";
	}
	private onSuccess4() {
		this.selectedFile4.pending = false;
		this.selectedFile4.status = "ok";
	}

	private onError4() {
		this.selectedFile4.pending = false;
		this.selectedFile4.status = "fail";
		this.selectedFile4.src = "";
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			dateExamen: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.dateExamen, [Validators.nullValidator]],
			entreprise: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.entreprise, [Validators.nullValidator]],
			numCage:    [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.numCage, [Validators.nullValidator]],
			espece:     [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.espece, [Validators.nullValidator]],
			ecloserie:  [this.data.list==='add'?this.ecloseries[13]:this.data.list==='delete'?"":this.data.examen.ecloserie, [Validators.nullValidator]],
			tempEau:    [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.tempEau, [Validators.nullValidator]],
			dateRecepetion: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.dateRecepetion, [Validators.nullValidator]],
			pm:         [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.pm, [Validators.nullValidator]],
			mortaliteparjours: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.mortaliteparjours, [Validators.nullValidator]],
			nbrJoursMortalite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.nbrJoursMortalite, [Validators.nullValidator]],
			totalMortalite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.totalMortalite, [Validators.nullValidator]],
			incidents: [this.data.list==='add'?this.incidents[12]:this.data.list==='delete'?"":this.data.examen.incident, [Validators.nullValidator]],

			embonpointobservation: [this.data.list==='add'?this.embonpoints[0]:this.data.list==='delete'?"":this.data.examen.embonpointobservation, [Validators.nullValidator]],
			embonpointfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.embonpointfrequence, [Validators.nullValidator]],
			embonpointseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.embonpointseverite, [Validators.nullValidator]],

			revetement: [this.data.list==='add'?this.revetements[0]:this.data.list==='delete'?"":this.data.examen.revetementobservation, [Validators.nullValidator]],
			revetementsfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.revetementfrequence, [Validators.nullValidator]],
			revetementsseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.revetementseverite, [Validators.nullValidator]],

			nageoires: [this.data.list==='add'?this.nageoires[0]:this.data.list==='delete'?"":this.data.examen.nageoiresobservation, [Validators.nullValidator]],
			nageoiresfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.nageoiresfrequence, [Validators.nullValidator]],
			nageoiresseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.nageoiresseverite, [Validators.nullValidator]],

			opercule: [this.data.list==='add'?this.opercules[0]:this.data.list==='delete'?"":this.data.examen.operculeobservation, [Validators.nullValidator]],
			operculefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.operculefrequence, [Validators.nullValidator]],
			operculeseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.operculeseverite, [Validators.nullValidator]],

			bouche: [this.data.list==='add'?this.bouches[0]:this.data.list==='delete'?"":this.data.examen.boucheobservation, [Validators.nullValidator]],
			bouchefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.bouchefrequence, [Validators.nullValidator]],
			boucheseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.boucheseverite, [Validators.nullValidator]],

			estomac: [this.data.list==='add'?this.estomacs[0]:this.data.list==='delete'?"":this.data.examen.estomacobservation, [Validators.nullValidator]],
			estomacfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.estomacfrequence, [Validators.nullValidator]],
			estomacseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.estomacseverite, [Validators.nullValidator]],

			intestin: [this.data.list==='add'?this.intestins[0]:this.data.list==='delete'?"":this.data.examen.intestinobservation, [Validators.nullValidator]],
			intestinfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.intestinfrequence, [Validators.nullValidator]],
			intestinseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.intestinseverite, [Validators.nullValidator]],

			branchie: [this.data.list==='add'?this.branchies[0]:this.data.list==='delete'?"":this.data.examen.branchieobservation, [Validators.nullValidator]],
			branchiefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.branchiefrequence, [Validators.nullValidator]],
			branchieseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.branchieseverite, [Validators.nullValidator]],

			coeur: [this.data.list==='add'?this.coeurs[0]:this.data.list==='delete'?"":this.data.examen.coeurobservation, [Validators.nullValidator]],
			coeurfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.coeurfrequence, [Validators.nullValidator]],
			coeurseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.coeurseverite, [Validators.nullValidator]],

			tete: [this.data.list==='add'?this.tetes[0]:this.data.list==='delete'?"":this.data.examen.teteobservation, [Validators.nullValidator]],
			tetefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.tetefrequence, [Validators.nullValidator]],
			teteseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.teteseverite, [Validators.nullValidator]],

			yeux: [this.data.list==='add'?this.yeux[0]:this.data.list==='delete'?"":this.data.examen.yeuxobservation, [Validators.nullValidator]],
			yeuxfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.yeuxfrequence, [Validators.nullValidator]],
			yeuxseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.yeuxseverite, [Validators.nullValidator]],

			cerveau: [this.data.list==='add'?this.cerveaux[0]:this.data.list==='delete'?"":this.data.examen.cerveauobservation, [Validators.nullValidator]],
			cerveaufrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.cerveaufrequence, [Validators.nullValidator]],
			cerveauseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.cerveauseverite, [Validators.nullValidator]],

			rate: [this.data.list==='add'?this.rates[0]:this.data.list==='delete'?"":this.data.examen.rateobservation, [Validators.nullValidator]],
			ratefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.ratefrequence, [Validators.nullValidator]],
			rateseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.rateseverite, [Validators.nullValidator]],

			foie: [this.data.list==='add'?this.foies[0]:this.data.list==='delete'?"":this.data.examen.foieobservation, [Validators.nullValidator]],
			foiefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.foiefrequence, [Validators.nullValidator]],
			foieseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.foieseverite, [Validators.nullValidator]],

			vesiculeBiliaire: [this.data.list==='add'?this.vesiculeBiliaires[0]:this.data.list==='delete'?"":this.data.examen.vesiculeBiliaireobservation, [Validators.nullValidator]],
			vesiculeBiliairefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.vesiculeBiliairefrequence, [Validators.nullValidator]],
			vesiculeBiliaireseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.vesiculeBiliaireseverite, [Validators.nullValidator]],

			rein: [this.data.list==='add'?this.reins[0]:this.data.list==='delete'?"":this.data.examen.reinobservation, [Validators.nullValidator]],
			reinfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.reinfrequence, [Validators.nullValidator]],
			reinseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.reinseverite, [Validators.nullValidator]],

			vessienatatoire: [this.data.list==='add'?this.vessienatatoires[0]:this.data.list==='delete'?"":this.data.examen.vessienatatoireobservation, [Validators.nullValidator]],
			vessienatatoirefrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.vessienatatoirefrequence, [Validators.nullValidator]],
			vessienatatoireseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.vessienatatoireseverite, [Validators.nullValidator]],

			autres: [this.data.list==='add'?this.autres[8]:this.data.list==='delete'?"":this.data.examen.autresobservation, [Validators.nullValidator]],
			autresfrequences: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.autresfrequence, [Validators.nullValidator]],
			autresseverite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.autresseverite, [Validators.nullValidator]],

			conduite: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.conduiteobservation, [Validators.nullValidator]],
      conduitetexte: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.conduiteconduitetexte, [Validators.nullValidator]],
			molecule: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.conduitemolecule, [Validators.nullValidator]],
			durretraitement: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.conduitedurretraitement, [Validators.nullValidator]],

			rechercheparasitaire: [this.data.list==='add'?"Rien à signaler":this.data.list==='delete'?"":this.data.examen.rechercheparasitaireobservation, [Validators.nullValidator]],
			rechercheparasitaireForme: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.rechercheparasitaireforme, [Validators.nullValidator]],
      parasitaire: [this.data.list==='add'?this.parasitaires[5]:this.data.list==='delete'?"":this.data.examen.parasitaire, [Validators.nullValidator]],

			recherchebacteriologique: [this.data.list==='add'?"Rien à signaler":this.data.list==='delete'?"":this.data.examen.recherchebacteriologiqueobservation, [Validators.nullValidator]],
			recherchebacteriologiqueforme: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.recherchebacteriologiqueforme, [Validators.nullValidator]],
      bacteriologique: [this.data.list==='add'?this.bacteriologiques[3]:this.data.list==='delete'?"":this.data.examen.bacteriologique, [Validators.nullValidator]],
      
			diagnostic: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.diagnosticobservation, [Validators.nullValidator]],
			diagnostictexte: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.diagnosticdiagnostictexte, [Validators.nullValidator]],
			diagnosticForme: [this.data.list==='add'?"":this.data.list==='delete'?"":this.data.examen.diagnosticforme, [Validators.nullValidator]],

		//	imagename: [this.data.list==='add'?"":this.data.examen.branchie.observation, [Validators.nullValidator]],
			
			
			
		});
	}
  onSubmit() {
        const dateExamen = this.tripForm.get("dateExamen").value;
        const entreprise = this.tripForm.get("entreprise").value;
        const numCage = this.tripForm.get("numCage").value;
        const espece = this.tripForm.get("espece").value;
        const ecloserie = this.tripForm.get("ecloserie").value;
        const tempEau = this.tripForm.get("tempEau").value;
        const dateRecepetion = this.tripForm.get("dateRecepetion").value;
        const pm = this.tripForm.get("pm").value;
        const mortaliteparjours = this.tripForm.get("mortaliteparjours").value;
        const nbrJoursMortalite = this.tripForm.get("nbrJoursMortalite").value;
        const totalMortalite = this.tripForm.get("nbrJoursMortalite").value;
        const incidents = this.tripForm.get("incidents").value;

        const embonpointfrequences = this.tripForm.get("embonpointfrequences").value;
        const embonpointseverite = this.tripForm.get("embonpointseverite").value;
        const embonpointobservation = this.tripForm.get("embonpointobservation").value;

        const revetementsfrequences = this.tripForm.get("revetementsfrequences").value;
        const revetementsseverite = this.tripForm.get("revetementsseverite").value;
        const revetement = this.tripForm.get("revetement").value;

        const nageoiresfrequences = this.tripForm.get("nageoiresfrequences").value;
        const nageoiresseverite = this.tripForm.get("nageoiresseverite").value;
        const nageoires = this.tripForm.get("nageoires").value;

        const operculefrequences = this.tripForm.get("operculefrequences").value;
        const operculeseverite = this.tripForm.get("operculeseverite").value;
        const opercule = this.tripForm.get("opercule").value;

        const bouchefrequences = this.tripForm.get("bouchefrequences").value;
        const boucheseverite = this.tripForm.get("boucheseverite").value;
        const bouche = this.tripForm.get("bouche").value;

        const estomacfrequences = this.tripForm.get("estomacfrequences").value;
        const estomacseverite = this.tripForm.get("estomacseverite").value;
        const estomac = this.tripForm.get("estomac").value;

        const intestinfrequences = this.tripForm.get("intestinfrequences").value;
        const intestinseverite = this.tripForm.get("intestinseverite").value;
        const intestin = this.tripForm.get("intestin").value;

        const branchiefrequences = this.tripForm.get("branchiefrequences").value;
        const branchieseverite = this.tripForm.get("branchieseverite").value;
        const branchie = this.tripForm.get("branchie").value;

        const coeurfrequences = this.tripForm.get("coeurfrequences").value;
        const coeurseverite = this.tripForm.get("coeurseverite").value;
        const coeur = this.tripForm.get("coeur").value;

        const tetefrequences = this.tripForm.get("tetefrequences").value;
        const teteseverite = this.tripForm.get("teteseverite").value;
        const tete = this.tripForm.get("tete").value;

        const yeuxfrequences = this.tripForm.get("yeuxfrequences").value;
        const yeuxseverite = this.tripForm.get("yeuxseverite").value;
        const yeux = this.tripForm.get("yeux").value;

        const cerveaufrequences = this.tripForm.get("cerveaufrequences").value;
        const cerveauseverite = this.tripForm.get("cerveauseverite").value;
        const cerveau = this.tripForm.get("cerveau").value;

        const ratefrequences = this.tripForm.get("ratefrequences").value;
        const rateseverite = this.tripForm.get("rateseverite").value;
        const rate = this.tripForm.get("rate").value;

        const foiefrequences = this.tripForm.get("foiefrequences").value;
        const foieseverite = this.tripForm.get("foieseverite").value;
        const foie = this.tripForm.get("foie").value;

        const vesiculeBiliairefrequences = this.tripForm.get("vesiculeBiliairefrequences").value;
        const vesiculeBiliaireseverite = this.tripForm.get("vesiculeBiliaireseverite").value;
        const vesiculeBiliaire = this.tripForm.get("vesiculeBiliaire").value;

        const reinfrequences = this.tripForm.get("reinfrequences").value;
        const reinseverite = this.tripForm.get("reinseverite").value;
        const rein = this.tripForm.get("rein").value;

        const rechercheparasitaireForme = this.tripForm.get("rechercheparasitaireForme").value;
        const rechercheparasitaire = this.tripForm.get("rechercheparasitaire").value;
        const parasitaire = this.tripForm.get("parasitaire").value;
        
        const recherchebacteriologiqueforme = this.tripForm.get("recherchebacteriologiqueforme").value;
        const recherchebacteriologique = this.tripForm.get("recherchebacteriologique").value;
        const bacteriologique = this.tripForm.get("bacteriologique").value;

        const diagnosticForme = this.tripForm.get("diagnosticForme").value;
        const diagnostic = this.tripForm.get("diagnostic").value;
        const diagnostictexte = this.tripForm.get("diagnostictexte").value;

        const conduite = this.tripForm.get("conduite").value;
        const conduitetexte = this.tripForm.get("conduitetexte").value;
        const molecule = this.tripForm.get("molecule").value;
        const durretraitement = this.tripForm.get("durretraitement").value;

        const vessienatatoirefrequences = this.tripForm.get("vessienatatoirefrequences").value;
        const vessienatatoireseverite = this.tripForm.get("vessienatatoireseverite").value;
        const vessienatatoire = this.tripForm.get("vessienatatoire").value;
        
        const autresfrequences = this.tripForm.get("autresfrequences").value;
        const autresseverite = this.tripForm.get("autresseverite").value;
        const autres = this.tripForm.get("autres").value;
        //const imagename = this.tripForm.get("imagename").value;
        const imageurl = this.fileUrl && this.fileUrl[0] !== "" ? this.fileUrl : "";
        const imageurl1 = this.fileUrl1 && this.fileUrl1[0] !== "" ? this.fileUrl1 : "";
        const imageurl2 = this.fileUrl2 && this.fileUrl2[0] !== "" ? this.fileUrl2 : "";
        const imageurl3 = this.fileUrl3 && this.fileUrl3[0] !== "" ? this.fileUrl3 : "";
        const imageurl4 = this.fileUrl4 && this.fileUrl4[0] !== "" ? this.fileUrl4 : "";
		const newAgriculteur = new Examen();
		newAgriculteur.dateExamen = dateExamen;
		newAgriculteur.imageurlconvert = this.base64textString;
		newAgriculteur.imageurlconvert1 = this.base64textString1;
		newAgriculteur.imageurlconvert2 = this.base64textString2;
		newAgriculteur.imageurlconvert3 = this.base64textString3;
		newAgriculteur.imageurlconvert4 = this.base64textString4;
		newAgriculteur.imageurl = imageurl;
		newAgriculteur.imageurl1 = imageurl1;
		newAgriculteur.imageurl2 = imageurl2;
		newAgriculteur.imageurl3 = imageurl3;
		newAgriculteur.imageurl4 = imageurl4;

		newAgriculteur.bacteriologique = bacteriologique;
		newAgriculteur.parasitaire = parasitaire;
		//newAgriculteur.image.push(imageurl) ;
		newAgriculteur.entreprise = entreprise;
		newAgriculteur.numCage = numCage;
		newAgriculteur.espece = espece;
		newAgriculteur.ecloserie = ecloserie;
		newAgriculteur.tempEau = tempEau;
		newAgriculteur.dateRecepetion = dateRecepetion;
		newAgriculteur.pm = pm;
		newAgriculteur.mortaliteparjours = mortaliteparjours;
		newAgriculteur.nbrJoursMortalite = nbrJoursMortalite;
		newAgriculteur.totalMortalite = totalMortalite;
		newAgriculteur.incident = incidents;

		newAgriculteur.embonpointobservation = embonpointobservation;
		newAgriculteur.embonpointfrequence = embonpointfrequences;
		newAgriculteur.embonpointseverite = embonpointseverite;
		/*newAgriculteur.embonpoint ={
      frequence : embonpointfrequences,
      severite : embonpointseverite,
      observation : embonpoint
    };*/

    newAgriculteur.revetementobservation = revetement;
		newAgriculteur.revetementfrequence = revetementsfrequences;
		newAgriculteur.revetementseverite = revetementsseverite;
		/*newAgriculteur.revetement={
      frequence : revetementsfrequences, 
		severite : revetementsseverite,
		observation : revetement,
    }*/

    newAgriculteur.nageoiresobservation = nageoires;
		newAgriculteur.nageoiresfrequence = nageoiresfrequences;
		newAgriculteur.nageoiresseverite = nageoiresseverite;
		/*newAgriculteur.nageoires={
      frequence : nageoiresfrequences,
      severite : nageoiresseverite,
      observation : nageoires
    }*/

    newAgriculteur.operculeobservation = opercule;
		newAgriculteur.operculefrequence = operculefrequences;
		newAgriculteur.operculeseverite = operculeseverite;
		/*newAgriculteur.opercule={
      frequence : operculefrequences,
	   	severite : operculeseverite,
		  observation : opercule
    }*/

    newAgriculteur.boucheobservation = bouche;
		newAgriculteur.bouchefrequence = bouchefrequences;
		newAgriculteur.boucheseverite = boucheseverite;
		/*newAgriculteur.bouche={
      frequence : bouchefrequences,
		  severite : boucheseverite,
		  observation : bouche
    }*/

    newAgriculteur.estomacobservation = estomac;
		newAgriculteur.estomacfrequence = estomacfrequences;
		newAgriculteur.estomacseverite = estomacseverite;
		/*newAgriculteur.estomac={
      frequence : estomacfrequences,
		  severite : estomacseverite,
		  observation : estomac
    }*/

    newAgriculteur.intestinobservation = intestin;
		newAgriculteur.intestinfrequence = intestinfrequences;
		newAgriculteur.intestinseverite = intestinseverite;
		/*newAgriculteur.intestin={
      frequence : intestinfrequences,
		  severite : intestinseverite,
		  observation : intestin
    }*/

    newAgriculteur.branchieobservation = branchie;
		newAgriculteur.branchiefrequence = branchiefrequences;
		newAgriculteur.branchieseverite = branchieseverite;
		/*newAgriculteur.branchie={
      frequence : branchiefrequences,
		  severite : branchieseverite,
		  observation : branchie
    }*/

    newAgriculteur.coeurobservation = coeur;
		newAgriculteur.coeurfrequence = coeurfrequences;
		newAgriculteur.coeurseverite = coeurseverite;
		/*newAgriculteur.coeur={
      frequence : coeurfrequences,
		  severite : coeurseverite,
		  observation : coeur
    }*/

    newAgriculteur.teteobservation = tete;
		newAgriculteur.tetefrequence = tetefrequences;
		newAgriculteur.teteseverite = teteseverite;
		/*newAgriculteur.tete={
      frequence :tetefrequences,
		  severite : teteseverite,
		  observation : tete
    }*/

    newAgriculteur.yeuxobservation = yeux;
		newAgriculteur.yeuxfrequence = yeuxfrequences;
		newAgriculteur.yeuxseverite = yeuxseverite;
		/*newAgriculteur.yeux={
      frequence : yeuxfrequences,
		  severite : yeuxseverite,
	  	observation : yeux
    }*/

    newAgriculteur.cerveauobservation = cerveau;
		newAgriculteur.cerveaufrequence = cerveaufrequences;
		newAgriculteur.cerveauseverite = cerveauseverite;
		/*newAgriculteur.cerveau={
      frequence : cerveaufrequences,
		  severite : cerveauseverite,
		  observation : cerveau
    }*/

    newAgriculteur.rateobservation = rate;
		newAgriculteur.ratefrequence = ratefrequences;
		newAgriculteur.rateseverite = rateseverite;
		/*newAgriculteur.rate={
      frequence :ratefrequences,
		  severite : rateseverite,
		  observation : rate
    }*/

    newAgriculteur.foieobservation = foie;
		newAgriculteur.foiefrequence = foiefrequences;
		newAgriculteur.foieseverite = foieseverite;
		/*newAgriculteur.foie={
      frequence :foiefrequences,
		  severite : foieseverite,
		  observation : foie
    }*/

    newAgriculteur.vesiculeBiliaireobservation = vesiculeBiliaire;
		newAgriculteur.vesiculeBiliairefrequence = vesiculeBiliairefrequences;
		newAgriculteur.vesiculeBiliaireseverite = vesiculeBiliaireseverite;
		/*newAgriculteur.vesiculeBiliaire={
      frequence : vesiculeBiliairefrequences,
		  severite : vesiculeBiliaireseverite,
		  observation : vesiculeBiliaire
    }*/

    newAgriculteur.reinobservation = rein;
		newAgriculteur.reinfrequence = reinfrequences;
		newAgriculteur.reinseverite = reinseverite;
		/*newAgriculteur.rein={
      frequence : reinfrequences,
		  severite : reinseverite,
		  observation : rein
    }*/

    newAgriculteur.rechercheparasitaireforme = rechercheparasitaireForme;
		newAgriculteur.rechercheparasitaireobservation = rechercheparasitaire;
    newAgriculteur.parasitaire= parasitaire;
		/*newAgriculteur.rechercheparasitaire={
      forme : rechercheparasitaireForme,
		  observation : rechercheparasitaire,
      
    }*/

    newAgriculteur.recherchebacteriologiqueforme = recherchebacteriologiqueforme;
		newAgriculteur.recherchebacteriologiqueobservation = recherchebacteriologique;
    newAgriculteur.bacteriologique= bacteriologique;
		/*newAgriculteur.recherchebacteriologique={
      forme : recherchebacteriologiqueforme,
		  observation : recherchebacteriologique,
    }*/

  
    newAgriculteur.vessienatatoirefrequence = vessienatatoirefrequences;
		newAgriculteur.vessienatatoireseverite = vessienatatoireseverite;
		newAgriculteur.vessienatatoireobservation = vessienatatoire;
		/*newAgriculteur.vessienatatoire={
      frequence : vessienatatoirefrequences,
		  severite :vessienatatoireseverite,
		  observation : vessienatatoire
    }*/

    newAgriculteur.diagnosticdiagnostictexte = diagnostictexte;
		newAgriculteur.diagnosticforme = diagnosticForme;
		newAgriculteur.diagnosticobservation = diagnostic;
		/*newAgriculteur.diagnostic={
      diagnostictexte : diagnostictexte,
      forme : diagnosticForme,
		  observation : diagnostic
    }*/

    newAgriculteur.conduiteconduitetexte = conduitetexte;
		newAgriculteur.conduiteobservation = conduite;
		newAgriculteur.conduitedurretraitement = durretraitement;
		newAgriculteur.conduitemolecule = molecule;
		/*newAgriculteur.conduite={
      conduitetexte:conduitetexte,
      observation : conduite,
		  molecule : molecule,
		  durretraitement : durretraitement
    }*/
    
    newAgriculteur.autresobservation = autres;
		newAgriculteur.autresseverite = autresseverite;
		newAgriculteur.autresfrequence = autresfrequences;
		/*newAgriculteur.autres={
      observation : autres,
		  frequence : autresfrequences,
		  severite : autresseverite
    }*/

		//newAgriculteur.dateCreation = new Date();
		this.TripService.saveExamen(newAgriculteur).then( res => {
		});
this.multiplefile =0;
  }
  base64textString:String="";
  base64textString1:String="";
  base64textString2:String="";
  base64textString3:String="";
  base64textString4:String="";
  _handleReaderLoaded(readerEvt) {
	var binaryString = readerEvt.target.result;
		   this.base64textString= btoa(binaryString);
		   console.log(btoa(binaryString));
   }
  _handleReaderLoaded1(readerEvt) {
	var binaryString = readerEvt.target.result;
		   this.base64textString1= btoa(binaryString);
		   console.log(btoa(binaryString));
   }
  _handleReaderLoaded2(readerEvt) {
	var binaryString = readerEvt.target.result;
		   this.base64textString2= btoa(binaryString);
		   console.log(btoa(binaryString));
   }
  _handleReaderLoaded3(readerEvt) {
	var binaryString = readerEvt.target.result;
		   this.base64textString3= btoa(binaryString);
		   console.log(btoa(binaryString));
   }
  _handleReaderLoaded4(readerEvt) {
	var binaryString = readerEvt.target.result;
		   this.base64textString4= btoa(binaryString);
		   console.log(btoa(binaryString));
   }
  processFile(imageInput: any) {
		this.fileisUploading = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		const reader1 = new FileReader();
		reader1.onload =this._handleReaderLoaded.bind(this);

        reader1.readAsBinaryString(file);
		reader.addEventListener("load", (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);

			this.selectedFile.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadImage(this.selectedFile.file).then(
				(url: string) => {
					this.fileUrl = url;
					this.fileisUploading = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
  processFile1(imageInput: any) {
		this.fileisUploading1 = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		const reader1 = new FileReader();
		reader1.onload =this._handleReaderLoaded1.bind(this);

        reader1.readAsBinaryString(file);
		reader.addEventListener("load", (event: any) => {
			this.selectedFile1 = new ImageSnippet(event.target.result, file);

			this.selectedFile1.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadImage(this.selectedFile1.file).then(
				(url: string) => {
					this.fileUrl1 = url;
					this.fileisUploading1 = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
  processFile2(imageInput: any) {
		this.fileisUploading2 = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		const reader1 = new FileReader();
		reader1.onload =this._handleReaderLoaded2.bind(this);

        reader1.readAsBinaryString(file);
		reader.addEventListener("load", (event: any) => {
			this.selectedFile2 = new ImageSnippet(event.target.result, file);

			this.selectedFile2.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadImage(this.selectedFile2.file).then(
				(url: string) => {
					this.fileUrl2 = url;
					this.fileisUploading2 = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
  processFile3(imageInput: any) {
		this.fileisUploading3 = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		const reader1 = new FileReader();
		reader1.onload =this._handleReaderLoaded3.bind(this);

        reader1.readAsBinaryString(file);
		reader.addEventListener("load", (event: any) => {
			this.selectedFile3 = new ImageSnippet(event.target.result, file);

			this.selectedFile3.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadImage(this.selectedFile3.file).then(
				(url: string) => {
					this.fileUrl3 = url;
					this.fileisUploading3 = false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
  processFile4(imageInput: any) {
		this.fileisUploading4 = true;
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		const reader1 = new FileReader();
		reader1.onload =this._handleReaderLoaded4.bind(this);

        reader1.readAsBinaryString(file);
		reader.addEventListener("load", (event: any) => {
			this.selectedFile4 = new ImageSnippet(event.target.result, file);

			this.selectedFile4.pending = true;
			this.idByDatesaveImage = Date.now().toString();
			this.imageService.uploadImage(this.selectedFile4.file).then(
				(url: string) => {
					this.fileUrl4 = url;
					this.fileisUploading4= false;
					this.fileUploaded = true;
					this.onSuccess();
				},
				(err) => {
					this.onError();
				}
			);
		});
		reader.readAsDataURL(file);
	}
  onUpdate(data) {		
	console.log(data);
	
        const entreprise = this.tripForm.get("entreprise").value;
        const dateExamen = this.tripForm.get("dateExamen").value;
        const numCage = this.tripForm.get("numCage").value;
        const espece = this.tripForm.get("espece").value;
        const ecloserie = this.tripForm.get("ecloserie").value;
        const tempEau = this.tripForm.get("tempEau").value;
        const dateRecepetion = this.tripForm.get("dateRecepetion").value;
        const pm = this.tripForm.get("pm").value;
        const mortaliteparjours = this.tripForm.get("mortaliteparjours").value;
        const nbrJoursMortalite = this.tripForm.get("nbrJoursMortalite").value;
        const totalMortalite = this.tripForm.get("nbrJoursMortalite").value;
        const incidents = this.tripForm.get("incidents").value;
        const embonpointfrequences = this.tripForm.get("embonpointfrequences").value;
        const embonpointseverite = this.tripForm.get("embonpointseverite").value;
        const embonpointobservation = this.tripForm.get("embonpointobservation").value;
        const revetementsfrequences = this.tripForm.get("revetementsfrequences").value;
        const revetementsseverite = this.tripForm.get("revetementsseverite").value;
        const revetement = this.tripForm.get("revetement").value;
        const nageoiresfrequences = this.tripForm.get("nageoiresfrequences").value;
        const nageoiresseverite = this.tripForm.get("nageoiresseverite").value;
        const nageoires = this.tripForm.get("nageoires").value;
        const operculefrequences = this.tripForm.get("operculefrequences").value;
        const operculeseverite = this.tripForm.get("operculeseverite").value;
        const opercule = this.tripForm.get("opercule").value;
        const bouchefrequences = this.tripForm.get("bouchefrequences").value;
        const boucheseverite = this.tripForm.get("boucheseverite").value;
        const bouche = this.tripForm.get("bouche").value;
        const estomacfrequences = this.tripForm.get("estomacfrequences").value;
        const estomacseverite = this.tripForm.get("estomacseverite").value;
        const estomac = this.tripForm.get("estomac").value;
        const intestinfrequences = this.tripForm.get("intestinfrequences").value;
        const intestinseverite = this.tripForm.get("intestinseverite").value;
        const intestin = this.tripForm.get("intestin").value;
        const branchiefrequences = this.tripForm.get("branchiefrequences").value;
        const branchieseverite = this.tripForm.get("branchieseverite").value;
        const branchie = this.tripForm.get("branchie").value;
        const coeurfrequences = this.tripForm.get("coeurfrequences").value;
        const coeurseverite = this.tripForm.get("coeurseverite").value;
        const coeur = this.tripForm.get("coeur").value;
        const tetefrequences = this.tripForm.get("tetefrequences").value;
        const teteseverite = this.tripForm.get("teteseverite").value;
        const tete = this.tripForm.get("tete").value;
        const yeuxfrequences = this.tripForm.get("yeuxfrequences").value;
        const yeuxseverite = this.tripForm.get("yeuxseverite").value;
        const yeux = this.tripForm.get("yeux").value;
        const cerveaufrequences = this.tripForm.get("cerveaufrequences").value;
        const cerveauseverite = this.tripForm.get("cerveauseverite").value;
        const cerveau = this.tripForm.get("cerveau").value;
        const ratefrequences = this.tripForm.get("ratefrequences").value;
        const rateseverite = this.tripForm.get("rateseverite").value;
        const rate = this.tripForm.get("rate").value;
        const foiefrequences = this.tripForm.get("foiefrequences").value;
        const foieseverite = this.tripForm.get("foieseverite").value;
        const foie = this.tripForm.get("foie").value;
        const vesiculeBiliairefrequences = this.tripForm.get("vesiculeBiliairefrequences").value;
        const vesiculeBiliaireseverite = this.tripForm.get("vesiculeBiliaireseverite").value;
        const vesiculeBiliaire = this.tripForm.get("vesiculeBiliaire").value;
        const reinfrequences = this.tripForm.get("reinfrequences").value;
        const reinseverite = this.tripForm.get("reinseverite").value;
        const rein = this.tripForm.get("rein").value;

        const rechercheparasitaireForme = this.tripForm.get("rechercheparasitaireForme").value;
        const rechercheparasitaire = this.tripForm.get("rechercheparasitaire").value;
        const parasitaire = this.tripForm.get("parasitaire").value;
        
        const recherchebacteriologiqueforme = this.tripForm.get("recherchebacteriologiqueforme").value;
        const recherchebacteriologique = this.tripForm.get("recherchebacteriologique").value;
        const bacteriologique = this.tripForm.get("bacteriologique").value;

        const diagnosticForme = this.tripForm.get("diagnosticForme").value;
        const diagnostic = this.tripForm.get("diagnostic").value;
        const diagnostictexte = this.tripForm.get("diagnostictexte").value;

        const conduite = this.tripForm.get("conduite").value;
        const conduitetexte = this.tripForm.get("conduitetexte").value;
        const molecule = this.tripForm.get("molecule").value;
        const durretraitement = this.tripForm.get("durretraitement").value;

        const vessienatatoirefrequences = this.tripForm.get("vessienatatoirefrequences").value;
        const vessienatatoireseverite = this.tripForm.get("vessienatatoireseverite").value;
        const vessienatatoire = this.tripForm.get("vessienatatoire").value;
        
        const autresfrequences = this.tripForm.get("autresfrequences").value;
        const autresseverite = this.tripForm.get("autresseverite").value;
        const autres = this.tripForm.get("autres").value;
        //const imageurl = this.fileUrl && this.fileUrl[0] !== "" ? this.fileUrl[0] : "";
		let a={
   // image :imageurl,
    dateExamen : dateExamen,
		entreprise : entreprise,
		numCage : numCage,
		espece : espece,
		ecloserie : ecloserie,
		tempEau : tempEau,
		dateRecepetion :dateRecepetion,
		pm : pm,
		mortaliteparjours : mortaliteparjours,
		nbrJoursMortalite : nbrJoursMortalite,
		totalMortalite : totalMortalite,
		incident : incidents,

    embonpointobservation : embonpointobservation,
    embonpointfrequence : embonpointfrequences,
    embonpointseverite : embonpointseverite,
		/*embonpoint :{
      frequence : embonpointfrequences,
      severite : embonpointseverite,
      observation : embonpoint
    },*/

    revetementobservation : revetement,
    revetementfrequence : revetementsfrequences,
    revetementseverite : revetementsseverite,
		/*revetement:{
      frequence : revetementsfrequences, 
		severite : revetementsseverite,
		observation : revetement,
    },*/

    nageoiresobservation : nageoires,
    nageoiresfrequence : nageoiresfrequences,
    nageoiresseverite : nageoiresseverite,
		/*nageoires:{
      frequence : nageoiresfrequences,
      severite : nageoiresseverite,
      observation : nageoires
    },*/

    operculeobservation : opercule,
    operculefrequence : operculefrequences,
    operculeseverite : operculeseverite,
		/*opercule:{
      frequence : operculefrequences,
	   	severite : operculeseverite,
		  observation : opercule
    },*/

    boucheobservation : bouche,
    bouchefrequence : bouchefrequences,
    boucheseverite : boucheseverite,
		/*bouche:{
      frequence : bouchefrequences,
		  severite : boucheseverite,
		  observation : bouche
    },*/

    estomacobservation : estomac,
    estomacfrequence : estomacfrequences,
    estomacseverite : estomacseverite,
		/*estomac:{
      frequence : estomacfrequences,
		  severite : estomacseverite,
		  observation : estomac
    },*/

    intestinobservation : intestin,
    intestinfrequence : intestinfrequences,
    intestinseverite : intestinseverite,
		/*intestin:{
      frequence : intestinfrequences,
		  severite : intestinseverite,
		  observation : intestin
    },*/

    branchieobservation : branchie,
    branchiefrequence : branchiefrequences,
    branchieseverite : branchieseverite,
		/*branchie:{
      frequence : branchiefrequences,
		  severite : branchieseverite,
		  observation : branchie
    },*/

    coeurobservation : coeur,
    coeurfrequence : coeurfrequences,
    coeurseverite : coeurseverite,
		/*coeur:{
      frequence : coeurfrequences,
		  severite : coeurseverite,
		  observation : coeur
    },*/

    teteobservation : tete,
    tetefrequence : tetefrequences,
    teteseverite : teteseverite,
		/*tete:{
      frequence :tetefrequences,
		  severite : teteseverite,
		  observation : tete
    },*/

    yeuxobservation : yeux,
    yeuxfrequence : yeuxfrequences,
    yeuxseverite : yeuxseverite,
		/*yeux:{
      frequence : yeuxfrequences,
		  severite : yeuxseverite,
	  	observation : yeux
    },*/

    cerveauobservation : cerveau,
    cerveaufrequence : cerveaufrequences,
    cerveauseverite : cerveauseverite,
		/*cerveau:{
      frequence : cerveaufrequences,
		  severite : cerveauseverite,
		  observation : cerveau
    },*/

    rateobservation : rate,
    ratefrequence : ratefrequences,
    rateseverite : rateseverite,
		/*rate:{
      frequence :ratefrequences,
		  severite : rateseverite,
		  observation : rate
    },*/

    foieobservation : foie,
    foiefrequence : foiefrequences,
    foieseverite : foieseverite,
		/*foie:{
      frequence :foiefrequences,
		  severite : foieseverite,
		  observation : foie
    },*/

    vesiculeBiliaireobservation : vesiculeBiliaire,
    vesiculeBiliairefrequence : vesiculeBiliairefrequences,
    vesiculeBiliaireseverite : vesiculeBiliaireseverite,
		/*vesiculeBiliaire:{
      frequence : vesiculeBiliairefrequences,
		  severite : vesiculeBiliaireseverite,
		  observation : vesiculeBiliaire
    },*/

    reinobservation : rein,
    reinfrequence : reinfrequences,
    reinseverite : reinseverite,
		/*rein:{
      frequence : reinfrequences,
		  severite : reinseverite,
		  observation : rein
    },*/

    rechercheparasitaireobservation : rechercheparasitaire,
    rechercheparasitaireforme : rechercheparasitaireForme,
    parasitaire: parasitaire,
		/*rechercheparasitaire:{
      forme : rechercheparasitaireForme,
		  observation : rechercheparasitaire
    },*/  

    recherchebacteriologiqueobservation : recherchebacteriologique,
    recherchebacteriologiqueforme : recherchebacteriologiqueforme,
    bacteriologique: bacteriologique,
		/*recherchebacteriologique:{
      forme : recherchebacteriologiqueforme,
		  observation : recherchebacteriologique
    },*/

    vessienatatoireobservation : vessienatatoire,
    vessienatatoirefrequence : vessienatatoirefrequences,
    vessienatatoireseverite : vessienatatoireseverite,
		/*vessienatatoire:{
      frequence : vessienatatoirefrequences,
		  severite :vessienatatoireseverite,
		  observation : vessienatatoire
    },*/

    diagnosticdiagnostictexte : diagnostictexte,
    diagnosticobservation : diagnostic,
    diagnosticforme : diagnosticForme,
		/*diagnostic:{
      diagnostictexte : diagnostictexte,
      forme : diagnosticForme,
		  observation : diagnostic
    },*/

    conduiteconduitetexte : conduitetexte,
    conduiteobservation : conduite,
    conduitemolecule : molecule,
    conduitedurretraitement : durretraitement,
		/*conduite:{
      conduitetexte:conduitetexte,
      observation : conduite,
		  molecule : molecule,
		  durretraitement : durretraitement
    },*/
    
    autresobservation : autres,
    autresfrequence : autresfrequences,
    autresseverite : autresseverite,
		/*autres:{
      observation : autres,
		  frequence : autresfrequences,
		  severite : autresseverite
    },*/
	imageurl : data.examen.imageurl===""?"":data.examen.imageurl,
	imageurlconvert : data.examen.imageurlconvert===""?"":data.examen.imageurlconvert,
	imageurl1 : data.examen.imageurl1===""?"":data.examen.imageurl1,
	imageurlconvert1 : data.examen.imageurlconvert1===""?"":data.examen.imageurlconvert1,
	imageurl2 : data.examen.imageurl2===""?"":data.examen.imageurl2,
	imageurlconvert2 : data.examen.imageurlconvert2===""?"":data.examen.imageurlconvert2,
	imageurl3 : data.examen.imageurl3===""?"":data.examen.imageurl3,
	imageurlconvert3 : data.examen.imageurlconvert3===""?"":data.examen.imageurlconvert3,
	imageurl4 : data.examen.imageurl4===""?"":data.examen.imageurl4,
	imageurlconvert4 : data.examen.imageurlconvert4===""?"":data.examen.imageurlconvert4,
    id: data.id
  }
  console.log('recherce');
  
  console.log(a.recherchebacteriologiqueforme);
  
		this.TripService.updateExamen(a).then( res => {
		});

  }
  ondelete(id) {
        
		this.TripService.deleteExamen(id);
    this.dialogRef.close();

  }
}
