import { environment } from "./../../../../../../environments/environment.prod";
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Conduite } from '../../../../../models/conduite';
import { ConduiteService } from "../../../../../core/apps/conduite.service";
@Component({
  selector: 'kt-addconduite',
  templateUrl: './addconduite.component.html',
  styleUrls: ['./addconduite.component.scss']
})
export class AddconduiteComponent implements OnInit {
	progress: number;
	tripForm: FormGroup;
	fileisUploading = false;
	fileUrl: string;
	fileUploaded = false;
	idByDatesaveImage: string;
	typeoperation;
	selectedValue: string ="";
  constructor(
    public dialogRef: MatDialogRef<AddconduiteComponent>,
    private formBuilder: FormBuilder,
    private TripService: ConduiteService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.initForm();
  }

	onNoClick() {
		this.dialogRef.close();
	}
	initForm() {
		this.tripForm = this.formBuilder.group({
			libelle: ["", [Validators.required]],
		});
	}
  onSubmit() {
        const libelle = this.tripForm.get("libelle").value;
		
		const newAgriculteur = new Conduite(libelle);
		//newAgriculteur.libelle = libelle;

		//newAgriculteur.dateCreation = new Date();
		this.TripService.saveConduite(newAgriculteur).then( res => {
		});

  }
  onUpdate(id) {
        const libelle = this.tripForm.get("libelle").value;
		
        let a = {
          id: id,
          libelle: libelle,
        };
		//newAgriculteur.libelle = libelle;

		//newAgriculteur.dateCreation = new Date();
		this.TripService.updateConduite(a).then( res => {
		});

  }
  ondelete(id) {
        
		this.TripService.deleteconduite(id);
    this.dialogRef.close();

  }
}
