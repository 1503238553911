import { map, takeUntil } from "rxjs/operators";

import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	MatDialog,
	MatPaginator,
	MatSort,
	MatTableDataSource,
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";
import { ImageService } from "../../../../core/apps/image.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { ModifalertComponent } from "./modifalert/modifalert.component";
import { AlertService } from "../../../../core/apps/alert.service";
import { Alert } from '../../../../models/alert';
import { UserDataService } from "../../../../core/apps/user-data.service";
import { User } from "../../../../models/user";
import { ImagealertComponent } from './imagealert/imagealert.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
	selector: "kt-alert",
	templateUrl: "./alert.component.html",
	styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
	statut = [
		"Délivré",
		"En cours de traitement",
		"traité et résolu",
		"Traité et non résolu",
	];
	displayedColumns = ["select", "Catégorie", "Image","Description", "Map", "Statut"];
	selection = new SelectionModel<Element>(false, []);
	Element: any;
	alert: Alert;
	alerttab = [];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		private firestore: AngularFirestore,
		private uploadService: ImageService,
		private alertService: AlertService,
		private userService: UserDataService,
		public dialog: MatDialog
	) {}
	destroy$: Subject<void> = new Subject();
	dataSource: MatTableDataSource<any>;
	fileUploads: any[];
	url = "";
	ngOnInit() {		
		this.dataSource = new MatTableDataSource([]);
		let sub = this.alertService.getListAlert().subscribe((actions) => {
			this.alerttab = [];
			return actions.map((a) => {
				let data = a.payload.doc.data() as Alert;
				data.id = a.payload.doc.id;
				let annonceToString = JSON.stringify(Object.assign({}, data));
				let stringToJsonObject = JSON.parse(annonceToString);
				this.alert = stringToJsonObject;
				this.alerttab.push(this.alert);
				this.dataSource.data = this.alerttab;
			});
		});
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => this.selection.select(row));
	}
	openDialogImage(element) {		
		let dialogRef = this.dialog.open(ImagealertComponent, {
			data: {
				id: element.id,
				category: element.category,
				city: element.city,
				discription: element.discription,
				image_url: element.image_url,
				latitude: element.latitude,
				longitude: element.longitude,
				statutAlert: element.statutAlert,
				user_uid: element.user_uid,
			},
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	openDialogStatut(element) {	
		let dialogRef = this.dialog.open(ModifalertComponent, {
			data: {
				id: element.id,
				category: element.category,
				city: element.city,
				discription: element.discription,
				image_url: element.image_url,
				latitude: element.latitude,
				longitude: element.longitude,
				statutAlert: element.statutAlert,
				user_uid: element.user_uid,
			},
			width: "600px",
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}
	pdf(selection) {
		this.alerttab = [];
		this.userService
			.getuserById(selection[0].user_uid)
			.subscribe((values: User) => {
				this.alert = new Alert(
					selection[0].category,
					selection[0].city,
					selection[0].discription,
					selection[0].image_url,
					selection[0].latitude,
					selection[0].longitude,
					selection[0].statutAlert,
					values,
				);
				let docDefinition = {
					content: [
						{
							text: this.alert.city,
							bold: true,
						},
						{
							text: "Catégorie du  Signalement",
							bold: true,
							alignment: "center",
						},
						{ text: " " },
						{
							text: this.alert.category,
							alignment: "center",
							fontSize: 20,
						},
						{ text: " " },
						{ text: " " },
						{
							text: "Coordonnées du citoyen",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },
						{ text: this.alert.user.name },
						{ text: this.alert.user.phoneNumber },
						{ text: " " },
						{ text: " " },
						{
							text: "Object du  Signalement",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },
						{ text: this.alert.category },
						{ text: " " },
						{ text: " " },
						{
							text: "Contenu du  Signalement",
							bold: true,
							alignment: "left",
							color: "red",
						},
						{ text: " " },

						{ text: this.alert.discription },
					],
				};
				pdfMake
					.createPdf(docDefinition)
					.download("Signalement de " + this.alert.user.name);
			});
	}
}

export interface Element {
	Titre: string;
	Date: number;
}

/*for(let i=0; i< this.annonces.length; i++){
	Element[i].title = this.annonces[i].title;
	Element[i].commune = this.annonces[i].commune;
	Element[i].date = this.annonces[i].date;
}*/
const ELEMENT_DATA: Element[] = [];
