import { User } from "./user";
export class Alert {
	id: string;
    category: string;
    city:string;
	discription: string;
	image_url: string;
	latitude: string;
	longitude: string;
	statutAlert: string;
	user: User;

	constructor(
		category: string,
		city: string,
		discription: string,
		image_url: string,
		latitude: string,
		longitude: string,
        statutAlert: string,
        user: User,
	) {
		this.category = category;
		this.city = city;
		this.discription = discription;
		this.image_url = image_url;
		this.latitude = latitude;
        this.longitude = longitude;
        this.statutAlert = statutAlert;
        this.user = user;
	}
}
