import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";
@Component({
  selector: 'kt-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})
export class IncidentComponent implements OnInit {

  incidents= environment.incidents;
  constructor() { }

  ngOnInit() {
  }

}
