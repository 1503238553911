import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";
@Component({
  selector: 'kt-frequences',
  templateUrl: './frequences.component.html',
  styleUrls: ['./frequences.component.scss']
})
export class FrequencesComponent implements OnInit {

  frequences= environment.frequences;
  constructor() { }

  ngOnInit() {
  }

}
