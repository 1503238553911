import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-molecules',
  templateUrl: './molecules.component.html',
  styleUrls: ['./molecules.component.scss']
})
export class MoleculesComponent implements OnInit {

  
  molecules= environment.molecules;
  constructor() { }

  ngOnInit() {
  }

}
