import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup } from "@angular/forms";
import { Notification } from "../../models/notification";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import {UserDataService} from './user-data.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Usertoken} from '../../models/usertoken';
import {User} from '../../models/user';
@Injectable({
	providedIn: "root",
})
export class NotificationService {
	currentMessage = new BehaviorSubject(null);
	lastTap= [];
	tab : Usertoken
	private urlApiGoogle = 'https://fcm.googleapis.com/fcm/send';
	constructor(private angularFireMessaging: AngularFireMessaging,
				private firestore: AngularFirestore,
				private userService: UserDataService,
				private http: HttpClient) {
		this.angularFireMessaging.messaging.subscribe(
			(_messaging) => {
				_messaging.onMessage = _messaging.onMessage.bind(_messaging);
				_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
			}
		)
	}
  getNotification(){
	 return  this.firestore
		  .collection("Notification", ref => ref.orderBy('time', 'desc'))
		  .valueChanges();
  }
  pushNotification(data){
	  const httpHeaders = new HttpHeaders(
		  {
			  'Content-Type': 'application/json',
			  'Authorization' :'key=AAAAivhU1tc:APA91bGJzocYcGWFnsZtMTLhh40rfV3CegoEma_dALE3MvgnhOMQFAoy4qw88RKJgHDfGSWOjDZ5KU8PilFCNoEitOKTh6YPxhP1jhl3A_2EHNjYV9wgXB4XcQpSw9BIROvkoDWfLfCw'
		  }
	  );
	  return this.http.post(this.urlApiGoogle, data , { headers: httpHeaders });
  }
	saveNotif(notification): Promise<Notification> {
		this.userService.getUserByCity()
			.subscribe((actions: User[]) => {
          actions.map(notif  => {
			  let notificationCor = {
				  "notification" : {
					  "title": notification.title,
					  "body": notification.body,
					  'time': notification.time
				  },
				  "to" :  notif.userToken
			  }
			  this.pushNotification(notificationCor).subscribe(
				  (data) =>  console.log ('Notification Send succus'),
				  (error) => console.log(error)
			  );
		  });
			});
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.collection("Notification/")
				.add(notification)
				.then(
					(res) => {},
					(err) => reject(err)
				);
		});
	}
	requestPermission() {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
			},
			(err) => {
				console.error('Unable to get permission to notify.', err);
			}
		);
	}
/*	receiveMessage() {
		this.angularFireMessaging.messages.subscribe(
			(payload) => {
				console.log("new message received. ", payload);
				this.currentMessage.next(payload);
			})
	}*/

	saveNotificationForSuggAlert(data){

	}


}
