import { Timestamp } from "rxjs/internal/operators/timestamp";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { NotificationService } from "../../../../../core/apps/notification.service";
import firebase from 'firebase';

@Component({
	selector: "kt-addnotification",
	templateUrl: "./addnotification.component.html",
	styleUrls: ["./addnotification.component.scss"],
})
export class AddnotificationComponent implements OnInit {
	progress: number;
	notifForm: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<AddnotificationComponent>,
		private formBuilder: FormBuilder,
		private annonceService: NotificationService
	) {}

	ngOnInit() {
		this.initForm();
	}
	onNoClick(): void {
		this.dialogRef.close();
	}

	initForm() {
		this.notifForm = this.formBuilder.group({
			title: ["", [Validators.required]],
			body: ["", [Validators.required]],
			time: ["", [Validators.required]],
		});
	}
	onSubmit() {
		const title = this.notifForm.get("title").value;
		const description = this.notifForm.get("body").value;
		const time = this.notifForm.get("time").value;
		const date= new Date(time)
		let notif = {
			body: description,
			time: firebase.firestore.Timestamp.fromDate(date),
			title: title,
		};
	    this.annonceService.saveNotif(notif);
	}
}
