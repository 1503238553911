export class User {
	city: string;
	name: string;
	phoneNumber: string;
	uid: string;
	userToken: string;
	user_img_photo_uri: string;


	constructor(city: string, name: string, phoneNumber: string, uid: string, userToken: string, user_img_photo_uri: string) {
		this.city = city;
		this.name = name;
		this.phoneNumber = phoneNumber;
		this.uid = uid;
		this.userToken = userToken;
		this.user_img_photo_uri = user_img_photo_uri;
	}
}
