import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";

@Component({
  selector: 'kt-estomac',
  templateUrl: './estomac.component.html',
  styleUrls: ['./estomac.component.scss']
})
export class EstomacComponent implements OnInit {

  estomacs= environment.estomacs;
  constructor() { }

  ngOnInit() {
  }

}
